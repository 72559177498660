import React from "react";
import classes from "./RegisterResult.module.scss";
import headerNoDiagnosisImage from "../../static/images/header_icon.svg";
import headerDiagnosisImage from "../../static/images/DiagnosisQuestionHeader.svg";
import teacherImage from "../../static/images/teacher-mail.svg";
import { useAtom } from "jotai";
import { mailAtom as mailDiagAtom } from "../diagnosis-result/DiagnosisResult";
import { mailTmpAtom } from "../register-temp-user/RegisterTempUser";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { useNavigate, useLocation } from "react-router-dom";

const RegisterResult = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const source = location.state?.from || "DiagnosisResult";

  const linkDestination =
    source === "RegisterTempUser" ? "/registration" : "/top";

  const breadcrumbData =
    source === "RegisterTempUser"
      ? [
          { displayName: "みらいいコンシェル" },
          { displayName: "メール送信完了" },
        ]
      : [
          { displayName: "みらいいコンシェル" },
          { displayName: "子育てとおカネの9タイプ診断結果" },
          { displayName: "メール送信完了" },
        ];

  const [mailTmp] = useAtom(mailTmpAtom);
  const [mailDiag] = useAtom(mailDiagAtom);
  const mail: string = source === "RegisterTempUser" ? mailTmp : mailDiag;

  return (
    <>
      <div className={classes.container}>
        <img
          className={classes.headerImage}
          src={
            source === "RegisterTempUser"
              ? headerNoDiagnosisImage
              : headerDiagnosisImage
          }
          alt="Header Image"
        />
        <img
          className={classes.teacherImage}
          src={teacherImage}
          alt="Teacher Image"
        />
        <div className={classes.title}>メールを送信しました</div>
        <div className={classes.email}>{mail}</div>
        <div className={classes.text}>
          に、「みらいいコンシェル{" "}
          <span className={classes.mailAddress}>
            &lt;support@miraii-concierge.jp&gt;
          </span>
          」からご案内のメールをお送りしました。 数分経っても届かない場合は、
          迷惑メールフォルダをご確認ください。
        </div>
        <div className={classes.captionTitle}>メールが届きませんか？</div>
        <div className={classes.caption}>
          入力いただいたメールアドレスが登録されていないか、
          登録いただいた際にメールアドレスが誤っていた可能性がございます。
          お手数ですが
          <span
            className={classes.link}
            onClick={() => navigate(linkDestination)}
          >
            こちらのリンク
          </span>
          から再度お試しください。
        </div>
      </div>
      <BreadCrumb data={breadcrumbData} />
    </>
  );
};

export default RegisterResult;
