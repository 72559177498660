import React, { useState, useEffect, useContext } from "react";
import classes from "./RegisterTempUser.module.scss";
import { useNavigate } from "react-router-dom";
import headerImage from "../../static/images/header_icon.svg";
import { postUnregisteredUsers } from "../../api/UsersApi";
import { atom, useAtom } from "jotai";
export const mailTmpAtom = atom<string>("");
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import TextForm, { TextFormResult } from "../../atoms/text-form/TextForm";
import { validators } from "../../common/utility/validator.util";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";

const RegisterTempUser = () => {
  const navigate = useNavigate();
  const postUnregisteredUserApi = postUnregisteredUsers();
  const [doneRegister, setDoneRegister] = useState<boolean>(false);
  const [mail, setMail] = useAtom(mailTmpAtom);
  const mailValidator = (value: string) => {
    const required = validators.required(value);
    if (!required.status) {
      return required;
    }
    return validators.mail(value);
  };

  const register = () => {
    //診断未実施画面は診断結果は未実施
    const diagnosisType = "no_diagnosis";
    console.log(mail);
    postUnregisteredUserApi.post({
      diagnosisType,
      emailAddress: mail,
    });
    setDoneRegister(true);
  };

  useEffect(() => {
    if (doneRegister && postUnregisteredUserApi.state?.status === "success") {
      navigate("/register-result", { state: { from: "RegisterTempUser" } });
    }
  }, [postUnregisteredUserApi]);

  useEffect(() => {
    // 画面上部から始まるようにする処理。
    // 全ての画面で利用できるようにすべき？
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.container}>
        <img src={headerImage} alt={headerImage} />
        <div className={classes.body}>
          <div className={classes.registerContainer}>
            <div className={classes.registerTitle}>会員登録（無料）</div>
            <div className={classes.registerHeader}>
              ドメイン指定受信を設定されている方にはメールが正しく届かないことがあります。
            </div>
            <div className={classes.registerHeader}>
              「@miraii-concierge.jp」の受信許可をお願いします。
            </div>
            <div className={classes.registerForm}>
              <TextForm
                label="メールアドレス"
                type="text"
                name={"mail"}
                value={mail}
                color="white"
                onChange={(args: TextFormResult) => setMail(args.value)}
                validator={mailValidator}
              />
            </div>
            <SimpleButton
              label="利用規約に同意して登録する"
              width="100%"
              color="orange"
              isRadius={true}
              onClick={register}
              disabled={mailValidator(mail).status === false}
            />
            <div className={classes.registerCaption}>
              <a target="_blank" href="serviceterm" className={classes.link}>
                利用規約
              </a>
              <span>に同意の上、登録へお進みください。</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.breadCrumb}>
            <BreadCrumb
              data={[
                { displayName: "みらいいコンシェル" },
                { displayName: "会員登録" },
              ]}
            />
        </div>
    </>
  );
};

export default RegisterTempUser;
