import { atom } from "jotai";
import { State } from "./State";
import { fetchBase } from "./BaseApi";
import { ItemType } from "./ActionsApi";

export type StaticFinanceItem = {
  majorCategoryId: string;
  majorCategoryName: string;
  subCategoryId: string;
  subCategoryName: string;
  itemNumber: number;
  itemId: string;
  itemType: ItemType;
  itemName: string;
  unit: string;
  periodicity: string;
  itemMinValue: number;
  itemAvgValue: number;
  itemMaxValue: number;
  itemValue: string;
  mandatory: string;
  explanation: string;
  dropListValue: any | null;
};

export type UserStaticFinance = {
  userId: string;
  statsId: string;
  statsName: string;
  staticFinanceItems: StaticFinanceItem[];
};

export type UsersStaticFinanceParam = {
  majorCategoryId: string;
};

const uri = "api/users/static-finance";

export const fetchUsersStaticFinanceAtom =
  atom<State<UserStaticFinance> | null>(null);

export const fetchUsersStaticFinance = () => {
  return fetchBase<UserStaticFinance, UsersStaticFinanceParam>(
    uri,
    fetchUsersStaticFinanceAtom
  );
};
