// Header.jsx
import React, { useEffect, useState } from "react";
import footerImage from "../../static/images/footer_icon.svg";
import exportImage from "../../static/images/export-icon.svg";
import arrowRight from "../../static/images/arrowRightWhite.png";
import styles from "./Footer.module.scss";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { diagnosisLoading } from "../../state/diagnosis-loading/DiagnosisLoadingAtom";
import { sideMenuAtom } from "../../state/side-menu/SideMenuAtom";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState<boolean>();
  const [loadingState, setLoadingState] = useAtom(diagnosisLoading);
  const [isMenu, setMenu] = useAtom(sideMenuAtom);

  // フッターが表示されないパスを指定
  const hidePathList = ["/first-setting", "/question"];
  useEffect(() => {
    if (
      hidePathList.includes(location.pathname) ||
      loadingState ||
      isMenu.isShow
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname, loadingState, isMenu]);

  return (
    <>
      {show && (
        <div className={styles.footerWrapper}>
          <div className={styles.footerTitle}>
            <img
              src={footerImage}
              alt="footerImage"
            />
          </div>
          <div className={styles.footerNav}>
            <div
              className={styles.navMenuWrapper}
              onClick={() => window.open("/swan/serviceterm", "_blank")}
            >
              <div className={styles.navMenu}>利用規約</div>
              <img
                src={arrowRight}
                alt="exportImage"
                className={styles.arrowRightImage}
              />
            </div>
            <div className={styles.divider}></div>
            <div
              className={styles.navMenuWrapper}
              onClick={() => window.open("/swan/privacy", "_blank")}
            >
              <div className={styles.navMenu}>プライバシーポリシー</div>
              <img
                src={arrowRight}
                alt="exportImage"
                className={styles.arrowRightImage}
              />
            </div>
            <div className={styles.divider}></div>
            <div
              className={styles.navMenuWrapper}
              onClick={() => window.open("https://miraii.jp/")}
            >
              <div className={styles.navMenu}>運営会社</div>
              <img
                src={exportImage}
                alt="exportImage"
                className={styles.exportImage}
              />
            </div>
            <div className={styles.divider}></div>
            <div className={styles.copyright}>©2025 miraii inc.</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
