import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchUser } from "../../api/UsersApi";
import Accordion from "../../molecules/accordion/Accordion";
import { gender, relationships } from "../first-setting/FirstSetting-const";
import DisplayList from "../../molecules/display-list/DisplayList";
import Header from "../../molecules/header/Header";
import PageTitle from "../../atoms/page-title/PageTitle";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import classes from "./FamilySettingDetail.module.scss";
import Avatar from "../../atoms/avatar/Avatar";

const FamilySettingDetail = () => {
  const [searchParams] = useSearchParams();
  const familySequence = searchParams.get("id");
  const fetchUserApi = fetchUser();
  const navigate = useNavigate();
  const [displayList, setDisplayList] = useState<
    { label: string; value: string; onClick?: () => void }[]
  >([]);
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (familySequence == null) {
      navigate("/family-setting");
    }
    fetchUserApi.fetch();
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      return;
    }

    const user = fetchUserApi.state.data[0].familyProfile.find(
      (p) => p.familySequence == familySequence
    );
    if (user?.familyRelationship === "myself") {
      setDisplayList([
        {
          label: "ニックネーム",
          value: user?.familyName ?? "",
        },
        {
          label: "子どもとの関係",
          value:
            relationships.find((r) => r.value === user?.childrenRelationship)
              ?.display ?? "",
        },
        {
          label: "誕生日",
          value: user?.birthdate ?? "",
        },
        {
          label: "居住エリア",
          value: fetchUserApi.state.data[0]?.postalCode ?? "",
        },
      ]);
    } else if (user?.familyRelationship === "partner") {
      setDisplayList([
        {
          label: "ニックネーム",
          value: user?.familyName ?? "",
        },
        {
          label: "子どもとの関係",
          value:
            relationships.find((r) => r.value === user?.childrenRelationship)
              ?.display ?? "",
        },
        {
          label: "誕生日",
          value: user?.birthdate ?? "",
        },
      ]);
    } else {
      setDisplayList([
        {
          label: "ニックネーム",
          value: user?.familyName ?? "",
        },
        {
          label: "性別",
          value: gender.find((g) => g.value === user?.gender)?.display ?? "",
        },
        {
          label: "誕生日",
          value: user?.birthdate ?? "",
        },
      ]);
    }

    setTitle(
      {
        myself: "あなたの情報",
        partner: "パートナー・配偶者の情報",
        children: "子どもの情報",
      }[user?.familyRelationship ?? ""] ?? ""
    );
    setName(user?.familyName ?? "");
  }, [fetchUserApi.state?.status]);

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.body}>
        <div className={classes.avatar}>
          <Avatar
            userName={name}
            width="80rem"
            height="80rem"
            fontSize="20rem"
          />
        </div>
        <div className={classes.profile}>
          <div className={classes.title}>{title}</div>
          <DisplayList list={displayList} />
        </div>
        <div className={classes.breadCrumb}>
          <BreadCrumb
            data={[
              { displayName: "みらいいコンシェル", url: "/my-page" },
              { displayName: "家族の基本情報", url: "/family-setting" },
              {
                displayName: title,
                url: `/family-setting-detail?id=${familySequence}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default FamilySettingDetail;
