import { atom } from "jotai";
import { fetchBase, postBase } from "./BaseApi";
import { State } from "./State";
import { RankCategory } from "./UsersActionApi";

export type UserFinanceItem = {
  itemId: string;
  majorCategoryId: string;
  majorCategoryName: string;
  subCategoryId: string;
  subCategoryName: string;
  itemName: string;
  itemNumber: number;
  unit: string;
  periodicity: string;
  itemValue: number | null;
  itemType: string;
  mandatory: string;
  explanation: string;
  dropListValue: any | null;
};

export type UsersFinance = {
  userId: string;
  userFinanceItems: UserFinanceItem[];
};

export type PostUsersItem = {
  itemId: string;
  itemValue: number;
  majorCategoryId: string;
};

export type PostUsersFinanceParam = {
  userFinanceItems: PostUsersItem[];
};

export type UsersFinanceParam = {
  majorCategoryId: string;
};

export type UserFinanceResponse = {
  userId: string;
  rankTitle: string;
  rankCategory: RankCategory;
  rankExplanation: string[];
  rankImageData: string;
  isRankup: boolean;
};

const uri = "api/users/finance";

export const fetchUsersFinanceAtom = atom<State<UsersFinance> | null>(null);
export const fetchUsersFinance = () => {
  return fetchBase<UsersFinance, UsersFinanceParam>(uri, fetchUsersFinanceAtom);
};

export const postUsersFinanceAtom = atom<State<UserFinanceResponse> | null>(
  null
);
export const postUsersFinance = () => {
  return postBase<UserFinanceResponse, PostUsersFinanceParam>(
    uri,
    postUsersFinanceAtom
  );
};
