import React, { useEffect, useState } from "react";
import classes from "./DiagnosisTop.module.scss";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { useNavigate } from "react-router-dom";
import headerImage from "../../static/images/DiagnosisTopHeader.svg";
import topImage from "../../static/images/DiagnosisTop.png";
import teacherImage from "../../static/images/teacher-memo.png";
import { fetchUser, User } from "../../api/UsersApi";

type Props = {
  isUnregistered: boolean;
};
function DiagnosisTop(props: Props) {
  const navigate = useNavigate();
  const userApi = fetchUser();

  useEffect(() => {
    if (!props.isUnregistered) {
      userApi.fetch();
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.banner}>
        子育てと資産形成のスタイル・教育資金のシミュレーション
      </div>
      <img className={classes.header} src={headerImage} alt={headerImage} />
      <img className={classes.top} src={topImage} alt={topImage} />
      <div
        className={classes.buttonArea}
        onClick={() => {
          if (props.isUnregistered) {
            navigate("/unregistered-diagnosis-contents");
          } else {
            navigate("/diagnosis-contents");
          }
        }}
      >
        <img className={classes.teacher} src={teacherImage} />
        <div className={classes.button}>診断スタート</div>
      </div>
      {!props.isUnregistered && (
        <>
          {userApi?.state?.data?.[0].diagnosisType !== "no_diagnosis" && (
            <div
              className={classes.link}
              onClick={() => navigate("/diagnosis-result")}
            >
              過去の診断はこちら
            </div>
          )}
          <div className={classes.link} onClick={() => navigate("/my-page")}>
            マイページ
          </div>
        </>
      )}
      <BreadCrumb
        data={[
          { displayName: "みらいいコンシェル" },
          { displayName: "子育てとおカネの9タイプ診断" },
        ]}
      />
    </div>
  );
}

export default DiagnosisTop;
