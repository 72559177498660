import { atom } from "jotai";
import { State } from "./State";
import { fetchBase } from "./BaseApi";

export type QuizContent = {
  quizId: string;
  quizSequence: number;
  correctAnswerRate:number;
  isCorrectChoice: boolean;
  question: string;
  explanation: string;
};

export type Quiz = {
  quizList: QuizContent[];
};

export type QuizParam = {
  quizId: string;
};

const uri = "api/quiz";

export const fetchQuizAtom = atom<State<Quiz> | null>(null);

export const fetchQuiz = () => {
  return fetchBase<Quiz, QuizParam>(uri, fetchQuizAtom);
};
