import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FamilyProfile, fetchUser, putUsers } from "../../api/UsersApi";
import Accordion from "../../molecules/accordion/Accordion";
import {
  childSchools,
  gender,
  getSchoolPlan,
  grade,
  relationships,
  schoolType,
  SchoolType,
  universityType,
} from "../first-setting/FirstSetting-const";
import DisplayList from "../../molecules/display-list/DisplayList";
import Header from "../../molecules/header/Header";
import PageTitle from "../../atoms/page-title/PageTitle";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import classes from "./ReplanEducation.module.scss";
import Avatar from "../../atoms/avatar/Avatar";
import SelectBox, { SelectBoxOption } from "../../atoms/select-box/SelectBox";

const ReplanEducation = () => {
  const fetchUserApi = fetchUser();
  const putUserApi = putUsers();
  const navigate = useNavigate();
  const [children, setChildren] = useState<FamilyProfile[]>([]);
  const [submitState, setSubmitState] = useState<boolean>(false);

  const [schools, setSchools] = useState<
    { schoolType: SchoolType; value: string }[][]
  >([]);

  useEffect(() => {
    putUserApi.reset();
    fetchUserApi.fetch();
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      return;
    }

    const c = fetchUserApi.state.data[0].familyProfile.filter(
      (p) => p.familyRelationship === "children"
    );

    setChildren(c);
  }, [fetchUserApi.state?.status]);

  useEffect(() => {
    if (children.length === 0) {
      return;
    }
    // const s = children.map((c) => {
    //   const schoolPlan = getSchoolPlan(c.currentGrade ?? "", -1);
    //   return schoolPlan
    //     .map((s) => {
    //       const keyValue = Object.entries(c).find((v) => v[0] === s.value);
    //       if (keyValue == null) {
    //         return null;
    //       }

    //       const value =
    //         keyValue[1] == null || keyValue[1] == "NA" ? "public" : keyValue[1];
    //       return { schoolType: s, value };
    //     })
    //     .filter((v) => v != null) as {
    //     schoolType: SchoolType;
    //     value: string;
    //   }[];
    // });
    // setSchools(s);
  }, [children]);

  const change = (
    e: SelectBoxOption,
    parentIndex: number,
    childIndex: number
  ) => {
    const s = schools.map((school, pIndex) => {
      if (pIndex === parentIndex) {
        return school.map((s, cIndex) => {
          if (cIndex === childIndex) {
            return {
              ...s,
              value: e.value,
            };
          }
          return s;
        });
      }

      return school;
    });
    setSchools(s);
  };

  const changeGrade = (e: SelectBoxOption, parentIndex: number) => {
    const c = children.map((child, index) => {
      if (index === parentIndex) {
        return {
          ...child,
          currentGrade: e.value,
        };
      }
      return child;
    });
    setChildren(c);
  };

  const submit = () => {
    // 一旦全部NAにする
    const tmp: FamilyProfile[] = children.map((child) => {
      const mp = new Map();
      childSchools.forEach((s) => mp.set(s.value, "NA"));
      const obj = Object.fromEntries(mp);
      return { ...child, ...obj };
    });

    // そのうえで選択された値だけ詰める
    const c: FamilyProfile[] = tmp.map((child, parentIndex) => {
      const school = schools[parentIndex];

      const mp = new Map();
      school.forEach((s) => mp.set(s.schoolType.value, s.value));
      const obj = Object.fromEntries(mp);
      return { ...child, ...obj };
    });

    putUserApi.put({ familyProfile: c });
    setSubmitState(true);
  };

  const cancel = () => {
    navigate("/lifeplan");
  };

  useEffect(() => {
    if (putUserApi.state?.status === "success" && submitState) {
      navigate("/lifeplan");
    }
  }, [putUserApi, submitState]);

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.body}>
        <PageTitle title={"教育費の再計算"} />
        {children.length > 0 &&
          children.length === schools.length &&
          children.map((child, parentIndex) => {
            return (
              <>
                <div className={classes.avatar}>
                  <Avatar
                    userName={child.familyName ?? ""}
                    width="80rem"
                    height="80rem"
                    fontSize="20rem"
                  />
                </div>
                <SelectBox
                  id={"current-grade" + "-" + parentIndex}
                  defaultValue={""}
                  hideNone={true}
                  title={{ name: "現在の学年", value: "currentGrade" }}
                  option={grade}
                  onChange={(e) => changeGrade(e, parentIndex)}
                ></SelectBox>

                {schools[parentIndex].map((school, childIndex) => {
                  return (
                    <>
                      <SelectBox
                        id={"school" + "-" + parentIndex + "-" + childIndex}
                        defaultValue={school.value}
                        title={school.schoolType}
                        hideNone={true}
                        option={
                          school.schoolType.value === "university"
                            ? universityType
                            : schoolType
                        }
                        onChange={(e) => change(e, parentIndex, childIndex)}
                      ></SelectBox>
                    </>
                  );
                })}
              </>
            );
          })}
        <div className={classes.buttonBlock}>
          <a className={classes.button} onClick={submit}>
            アクションを完了する
          </a>
          <a className={`${classes.button} ${classes.cancel}`} onClick={cancel}>
            キャンセル
          </a>
        </div>

        <div className={classes.breadCrumb}>
          <BreadCrumb
            data={[
              { displayName: "みらいいコンシェル", url: "/my-page" },
              { displayName: "ライフプラン", url: "/lifeplan" },
              { displayName: "教育費の再計算", url: "/replan-education" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ReplanEducation;
