import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FinancePartner, fetchFinancePartner } from "../../api/FinancePartners";
import { User, fetchUser } from "../../api/UsersApi";
import { DiagnosisResults, fetchDiagnosis } from "../../api/UsersDiagnosisApi";
import Avatar from "../../atoms/avatar/Avatar";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import Card from "../../molecules/card/Card";
import DiagnosisNavigate from "../../molecules/diagnosis-navigate/DiagnosisNavigate";
import Header from "../../molecules/header/Header";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import FrameTemplate from "../../organism/frame-template/FrameTemplate";
import UserInfo from "../../organism/user-info/UserInfo";
import adequacy100 from "../../static/images/adequacy100.svg";
import adequacy0 from "../../static/images/adequacy0.svg";
import adequacy25 from "../../static/images/adequacy25.svg";
import adequacy33 from "../../static/images/adequacy33.svg";
import adequacy50 from "../../static/images/adequacy50.svg";
import adequacy67 from "../../static/images/adequacy67.svg";
import adequacy75 from "../../static/images/adequacy75.svg";
import graph from "../../static/images/adequecyGraph.svg";
import chara from "../../static/images/chara1.svg";
import greenBadge from "../../static/images/greenBadge.png";
import lockImage from "../../static/images/lock-icon.svg";
import rainbowBadge from "../../static/images/rainbowBadge.png";
import yellowBadge from "../../static/images/yellowBadge.png";
import classes from "./MyPage.module.scss";
import {
  fetchUsersEducationFee,
  UsersEducationFee,
} from "../../api/UsersEducationApi";
import CheckGreenIcon from "../../static/images/check-green-icon.png";
import CheckGreyIcon from "../../static/images/check-grey-icon.png";
import { round } from "../../common/utility/number.util";
import FinancialPlanBanner from "../../organism/financial-plan-banner/FinancialPlanBanner";
import { RankCategory } from "../../api/UsersActionApi";
import { fetchUsersFinance } from "../../api/UsersFinanceApi";

const MyPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [educationFee, setEducationFee] = useState<UsersEducationFee | null>(
    null
  );
  const [results, setResults] = useState<DiagnosisResults | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [financePartner, setFinancePartner] = useState<FinancePartner | null>(
    null
  );
  const [lifeDataCount, setLifeDataCount] = useState(0);

  const fetchUserApi = fetchUser();
  const diagnosisApi = fetchDiagnosis();
  const financePartnerApi = fetchFinancePartner();
  const userEducationApi = fetchUsersEducationFee();
  const userFinanceApi = fetchUsersFinance();

  useEffect(() => {
    fetchUserApi.fetch();
    userEducationApi.fetch();
    userFinanceApi.fetch({
      majorCategoryId: "ALL",
    });
  }, []);

  useEffect(() => {
    if (
      fetchUserApi.state?.data == null ||
      userEducationApi.state?.data == null ||
      userFinanceApi.state?.data == null
    ) {
      return;
    }
    setUser(fetchUserApi.state?.data?.[0]);
    setEducationFee(userEducationApi.state?.data?.[0]);
    setLifeDataCount(
      userFinanceApi.state?.data?.[0].userFinanceItems.filter((item) => {
        return item.itemValue != null;
      }).length
    );
  }, [
    fetchUserApi.state?.data,
    userEducationApi.state?.data,
    userFinanceApi.state?.data,
  ]);

  useEffect(() => {
    if (user == null) {
      return;
    }
    const diagnosisType = user.diagnosisType;
    diagnosisApi.fetch({
      diagnosisType,
    });
    const userName = user.userName ?? "ゲスト";

    setUserName(userName);

    const fpId = user.fpId;
    if (fpId != null) {
      financePartnerApi.fetch({
        fpId,
      });
    }
  }, [user]);

  useEffect(() => {
    if (diagnosisApi.state?.data == null) {
      return;
    }
    setResults(diagnosisApi.state?.data[0]);
  }, [diagnosisApi.state?.data]);

  useEffect(() => {
    if (financePartnerApi.state?.data == null) {
      return;
    }
    setFinancePartner(financePartnerApi.state.data[0]);
  }, [financePartnerApi.state?.data]);

  const createAdequacy = () => {
    if (user?.diagnosisType === "no_diagnosis") {
      return adequacy0;
    }
    switch (user?.currentRank) {
      case "platinum":
        return adequacy100;
      case "gold":
        return adequacy75;
      case "silver":
        return adequacy50;
      case "bronze":
        return adequacy25;
    }

    return adequacy25;
  };

  const selectIcon = (rank: RankCategory) => {

    // 教育費用が0円だった場合は、すべての進捗率をゼロとする
    if (educationFee?.totalTuitionFee == 0){
      return [CheckGreyIcon, CheckGreyIcon, CheckGreyIcon, CheckGreyIcon];
    }
    //if (user?.diagnosisType === "no_diagnosis") {
    //  return [CheckGreyIcon, CheckGreyIcon, CheckGreyIcon, CheckGreyIcon];
    //}
    switch (rank) {
      case "beginer":
        return [CheckGreenIcon, CheckGreyIcon, CheckGreyIcon, CheckGreyIcon];
      case "bronze":
        return [CheckGreenIcon, CheckGreyIcon, CheckGreyIcon, CheckGreyIcon];
      case "silver":
        return [CheckGreenIcon, CheckGreenIcon, CheckGreyIcon, CheckGreyIcon];
      case "gold":
        return [CheckGreenIcon, CheckGreenIcon, CheckGreenIcon, CheckGreyIcon];
      case "platinum":
        return [CheckGreenIcon, CheckGreenIcon, CheckGreenIcon, CheckGreenIcon];
    }
  };

  const checkEducationFee = () => {
    if (user == null) {
      return;
    }
    return (
      // 教育費用結果によってライフプランのロック解除を判定
      educationFee?.totalTuitionFee == 0
//      (user.numberOfChildren === 0 && user.currentRank === "bronze") ||
//      user.diagnosisType === "no_diagnosis"
    );
  };

  const createLifePlanStyle = () => {
    if (user?.currentRank === "platinum") {
      return { backgroundColor: "#ED7E99" };
    }
    return { backgroundColor: "#008E7E" };
  };

  const avaterColor = ["#6BD2C0", "#7CC0FF", "#EE8699", "#D08FEF"];
  return (
    <div className={classes.container}>
      {user != null && (
        <>
          <div className={classes.headerArea}>
            <Header />
            <div className={classes.userInfo}>
              {userName && (
                <UserInfo userRank={user.currentRank} userName={userName} />
              )}
            </div>
          </div>
          <div className={classes.contentContainer}>
            {user.numberOfCompletedAction === 0 && (
              <div className={classes.teacherComment}>
                <TeacherComment
                  image={chara}
                  comment={["まずはアクションを1つ完了してみましょう"]}
                ></TeacherComment>
              </div>
            )}
            <div className={classes.content}>
              <div className={classes.frameContentWrapper}>
                <div className={classes.frameContainer}>
                  <FrameTemplate
                    frameTitle={["◯×クイズ"]}
                    onClick={() => navigate("/quiz-list")}
                  >
                    <div className={classes.action}>
                      <div className={classes.actionNum}>
                        {user.numberOfCompletedAction}
                      </div>
                      <div className={classes.actionNumSub}>/30</div>
                      <div className={classes.actionText}>完了</div>
                    </div>
                  </FrameTemplate>
                </div>
                <div className={classes.frameContainer}>
                  <FrameTemplate
                    frameTitle={["バッジリスト"]}
                    onClick={() => navigate("/badge-list")}
                  >
                    <div className={classes.badge}>
                      <div className={classes.badgeCircle}>
                        <img
                          src={rainbowBadge}
                          alt="badge"
                          className={classes.badgeImg}
                        />
                        <div
                          className={classes.inCircle}
                          style={{ backgroundColor: "#FFF7D4" }}
                        >
                          {user.numberOfCompletedRainbowbadge}
                        </div>
                      </div>
                      <div className={classes.badgeCircle}>
                        <img
                          src={yellowBadge}
                          alt="badge"
                          className={classes.badgeImg}
                        />
                        <div
                          className={classes.inCircle}
                          style={{ backgroundColor: "#FFF7D4" }}
                        >
                          {user.numberOfCompletedYellowbadge}
                        </div>
                      </div>
                      <div className={classes.badgeCircle}>
                        <img
                          src={greenBadge}
                          alt="badge"
                          className={classes.badgeImg}
                        />
                        <div
                          className={classes.inCircle}
                          style={{ backgroundColor: "#E1F8F3" }}
                        >
                          {user.numberOfCompletedGreenbadge}
                        </div>
                      </div>
                    </div>
                  </FrameTemplate>
                </div>
              </div>
              <div className={classes.frameContentWrapper}>
                <div className={classes.frameContainer}>
                  <FrameTemplate
                    frameTitle={["教育資金", "シミュレーション"]}
                    height="106rem"
                    onClick={() => navigate("/simulate-education")}
                  >
                    <div className={classes.action}>
                      <div className={classes.actionNum}>
                        {educationFee?.childrenEducation?.length === 0
                          ? "-"
                          : round(
                              (educationFee?.totalTuitionFee ?? 0) / 10000,
                              "ceil",
                              0
                            )}
                      </div>
                      <div className={classes.actionText}>万円</div>
                    </div>
                  </FrameTemplate>
                </div>
                <div className={classes.frameContainer}>
                  <FrameTemplate
                    frameTitle={["ライフデータ"]}
                    height="106rem"
                    onClick={() => {
                      if (user.currentRank === "beginer") return;
                      navigate("/life-data");
                    }}
                  >
                    <div className={classes.action}>
                      {user.currentRank === "beginer" ? (
                        <div className={classes.rock}>
                          <img
                            className={classes.icon}
                            src={lockImage}
                            alt={lockImage}
                          />
                          <div className={classes.caption}>
                            ブロンズランクで解放
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={classes.actionNum}>
                            {lifeDataCount}
                          </div>
                          <div className={classes.actionText}>完了</div>
                        </>
                      )}
                    </div>
                  </FrameTemplate>
                </div>
              </div>
            </div>
            <div className={classes.wideFrameContainer}>
              {user.diagnosisType === "no_diagnosis" ? (
                <DiagnosisNavigate />
              ) : (
                <FrameTemplate
                  frameTitle={["子育てとおカネの9タイプ診断 - 結果"]}
                  onClick={() => navigate("/diagnosis-result")}
                >
                  <div className={classes.diagnosis}>
                    <div className={classes.diagnosisTitle}>あなたは</div>
                    <div className={classes.diagnosisContent}>
                      {results && results.typeName}タイプ
                    </div>
                  </div>
                </FrameTemplate>
              )}
            </div>
            <div className={classes.wideFrameContainer}>
              <FrameTemplate
                frameTitle={["ライフプラン"]}
                frameContentPadding="10rem 0 0"
                onClick={() => {
                  !checkEducationFee() && navigate("/lifeplan");
                }}
              >
                <div className={classes.adequecyContainer}>
                  {/* 子供が0人の場合、ブロンズはロックがかかる */}
                  {/* それ以外は、ライフプランの達成率を表示 */}
                  {checkEducationFee() && (
                    <div className={classes.rock}>
                      <img
                        className={classes.icon}
                        src={lockImage}
                        alt={lockImage}
                      />
                      <div className={classes.caption}>
                        教育資金シミュレーション実施で解放
                      </div>
                    </div>
                  )}
                  <>
                    <div className={classes.imageArea}>
                      <img
                        src={createAdequacy()}
                        alt="adequency"
                        className={classes.adequency}
                      />
                      <div className={classes.checkArea}>
                        {(() => {
                          const checkList = selectIcon(user.currentRank);
                          return (
                            <>
                              <div className={classes.check}>
                                <img src={checkList[0]} alt={checkList[0]} />
                                <div className={classes.text}>教育費</div>
                              </div>
                              <div className={classes.check}>
                                <img src={checkList[1]} alt={checkList[1]} />
                                <div className={classes.text}>収支（現在）</div>
                              </div>
                              <div className={classes.check}>
                                <img src={checkList[2]} alt={checkList[2]} />
                                <div className={classes.text}>収支（老後）</div>
                              </div>
                              <div className={classes.check}>
                                <img src={checkList[3]} alt={checkList[3]} />
                                <div className={classes.text}>資産</div>
                              </div>
                            </>
                          );
                        })()}
                      </div>
                      <img src={graph} alt="graph" className={classes.graph} />
                    </div>
                  </>
                  )
                  <div
                    className={classes.adequencyMessage}
                    style={createLifePlanStyle()}
                  >
                    {user.currentRank === "platinum" ? (
                      <span>ライフプランの完成！</span>
                    ) : (
                      <span>ランクアップするごとにライフプランが充実</span>
                    )}
                  </div>
                </div>
              </FrameTemplate>
            </div>
            <div className={classes.bannerCounselling}>
              <div className={classes.bubble}>
                {user?.fpId == null
                  ? "お金のことで気になることがありましたらなんでもお気軽にご相談ください"
                  : "なんでもお気軽にご相談ください"}
              </div>
              {user?.fpId == null ? (
                <FinancialPlanBanner />
              ) : (
                <Card
                  name={
                    financePartner?.isFinancialPartner
                      ? `${financePartner?.lastName} ${financePartner?.firstName}`
                      : "担当のパートナーは\n登録されていません"
                  }
                  subName="担当フィナンシャルプランナー"
                  image={financePartner?.fpImageData}
                  content={financePartner?.fpProfile}
                />
              )}
            </div>
          </div>
          <div className={classes.breadCrumb}>
            <BreadCrumb
              data={[
                { displayName: "みらいいコンシェル", url: "/my-page" },
                { displayName: "マイページ", url: "/my-page" },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MyPage;
