import { useEffect, useState } from "react";
import React from "react";
import classes from "./TextForm.module.scss";

export type TextFormProps = {
  type?: "text" | "number" | "password" | "date";
  label?: string;
  subLabel?: string[];
  name: string;
  value: string | null;
  disabled?: boolean;
  placeholder?: string;
  color?: "white" | "black";
  align?: "right";
  inputWidth?: string;
  rightIcon?: string;
  validator?: (value: string) => { status: boolean; message?: string };
  onChange: (args: TextFormResult) => void;
  onClickRightButton?: () => void;
};

export type TextFormResult = {
  status: boolean;
  value: string;
  name: string;
};
type ValidatorResult = {
  status: boolean;
  message?: string;
};

const TextForm = (props: TextFormProps) => {
  const inputType = props.type ? props.type : "text";

  const [value, setValue] = useState<string>();
  const [result, setResult] = useState<ValidatorResult>({ status: true });
  const [onTouch, setOnTouch] = useState(false);

  useEffect(() => {
    change(props.value ?? "");
  }, [props.value]);

  const change = (value: string) => {
    const validatorResult = props.validator?.(value) ?? { status: true };
    setValue(value);
    setResult(validatorResult);
    props.onChange({ status: validatorResult.status, value, name: props.name });
  };

  const onChange = (value: string) => {
    setOnTouch(true);
    change(value);
  };

  const getStyle = () => {
    let color = { backgroundColor: "white", color: "black" };
    let layout = {};
    let width = {};
    if (props.color == null) {
      color = { backgroundColor: "#0a0d10", color: "white" };
    }

    if (props.color === "black") {
      color = { backgroundColor: "#0a0d10", color: "white" };
    }

    if (props.align === "right") {
      layout = { textAlign: "right" };
    }

    if (props.inputWidth) {
      width = { width: props.inputWidth };
    }

    return { ...color, ...layout, ...width };
  };

  return (
    <div className={classes.container}>
      <label className={classes.label}>{props.label}</label>
      {props.subLabel?.map((subLabel, index) => (
        <label className={classes.subLabel} key={index}>
          {subLabel}
        </label>
      ))}
      <input
        style={getStyle()}
        placeholder={props.placeholder}
        className={classes.input}
        name={props.name}
        type={inputType}
        value={props.value ?? ""}
        disabled={props.disabled ?? false}
        onChange={(e) => onChange(e.target.value)}
      />
      {props.rightIcon && (
        <img
          src={props.rightIcon}
          alt="rightIcon"
          className={classes.rightIcon}
          onClick={props.onClickRightButton}
        />
      )}
      <div className={classes.error}>
        {!onTouch || result.status ? "" : result.message}
      </div>
    </div>
  );
};

export default TextForm;
