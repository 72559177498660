import React, { useEffect } from "react";
import classes from "./DiagnosisQuestion.module.scss";

export type DiagnosisQuestion = {
  questionNo: number;
  question: string[];
  questionType?: "education-plan";
  logo: string;
  answerList: AnswerList[];
};

type AnswerList = {
  answerNo: number;
  answer: string;
};

type Props = {
  content: DiagnosisQuestion;
  answerCallback: (questionNo: number, answerNo: number) => void;
  prevCallback: (questionNo: number) => void;
  hidePrev: boolean;
  selectedNo?: number;
};

const DiagnosisQuestion = (props: Props) => {
  return (
    <div className={classes.content}>
      <div className={classes.header}>Q{props.content.questionNo}</div>
      <div className={classes.question}>
        {props.content.question.map((q) => (
          <div key={q}>{q}</div>
        ))}
      </div>
      <img className={classes.logo} src={props.content.logo} />
      {props.content.answerList.map((elm) => {
        return (
          <div
            onClick={() =>
              props.answerCallback(props.content.questionNo, elm.answerNo)
            }
            className={
              props.selectedNo != null && elm.answerNo === props.selectedNo
                ? `${classes.button} ${classes.active}`
                : classes.button
            }
            key={elm.answerNo}
          >
            <span className={classes.text}>{elm.answer}</span>
          </div>
        );
      })}
      {!props.hidePrev && (
        <div
          className={classes.link}
          onClick={() => props.prevCallback(props.content.questionNo)}
        >
          ひとつ前に戻る
        </div>
      )}
    </div>
  );
};

export default DiagnosisQuestion;
function uesEffect(arg0: () => void, arg1: Props[]) {
  throw new Error("Function not implemented.");
}
