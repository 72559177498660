import React, { useEffect, useState } from "react";
import classes from "./Quiz.module.scss";
import { QuizType } from "../../api/ActionsApi";
import circleBlack from "../../static/images/circle.svg";
import circleWhite from "../../static/images/circle-white.svg";
import circleGray from "../../static/images/circle-gray.svg";
import closeBlack from "../../static/images/close.svg";
import closeWhite from "../../static/images/close-white.svg";
import closeGray from "../../static/images/close-gray.svg";
import { QuizContent } from "../../api/QuizApi";
import QuizCorrectImage from "../../static/images/quiz-correct.svg";
import QuizMistakeImage from "../../static/images/quiz-mistake.svg";
import Thinking from "../../static/images/thinking.gif";
import Correct from "../../static/images/correct.gif";
import Incorrect from "../../static/images/incorrect.gif";

type Props = {
  data: QuizContent;
  index: number;
  total: number;
  submit: (result: boolean) => void;
  next: () => void;
};
const Quiz = (props: Props) => {
  const [result, setResult] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [answer, setAnswer] = useState<boolean>(false);
  const [showImage, setShowImage] = useState(false);
  const [displayImage, setDisplayImage] = useState(QuizCorrectImage);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const getButton = (type: boolean) => {
    if (isSubmit) {
      if (answer === type) {
        return type ? circleWhite : closeWhite;
      }
      return type ? circleGray : closeGray;
    }

    return type ? circleBlack : closeBlack;
  };

  const getButtonClass = (type: boolean) => {
    if (!isSubmit) {
      return;
    }

    if (type !== answer) {
      return;
    }

    return answer === props.data.isCorrectChoice
      ? classes.correct
      : classes.incorrect;
  };

  const getAnswerLabelStyle = () => {
    if (result) {
      return {
        color: "#1784E9",
      };
    }

    return {
      color: "#747474",
    };
  };

  const submit = (answer: boolean) => {
    if (isSubmit) {
      return;
    }

    const isCorrect = props.data.isCorrectChoice === answer;

    setDisplayImage(isCorrect ? QuizCorrectImage : QuizMistakeImage);
    setShowImage(true);
    setResult(isCorrect);
    setAnswer(answer);

    timeoutRef.current = setTimeout(() => {
      setIsSubmit(true);
      setShowImage(false);
      props.submit(isCorrect);
      props.next();
    }, 1000);
  };

  useEffect(() => {
    setIsSubmit(false);
    setResult(false);
    setAnswer(false);
    // 次の問題に遷移したときに影響が出ないようにするためタイムアウトをクリアする
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [props.index]);

  const imageClick = () => {
    setShowImage(false);
    setIsSubmit(true);
    props.submit(false);
    props.next();
  };

  return (
    <div className={classes.container}>
      <div className={classes.question}>
      <div className={classes.header}>
        <div className={classes.centerGroup}>
          <div className={classes.index}>Q{props.index}</div>
          <div className={classes.slash}>/</div>
          <div className={classes.total}>{props.total}</div>
        </div>
        <div className={classes.rate}>
          みんなの正答率{props.data.correctAnswerRate}%
        </div>
      </div>
        <div className={classes.text}>{props.data.question}</div>
        {showImage && (
          <img
            className={classes.quizImage}
            src={displayImage}
            alt={displayImage}
            onClick={imageClick}
          />
        )}
        <div className={classes.buttonBlock}>
          <a
            className={`${classes.button} ${getButtonClass(true)}`}
            onClick={() => submit(true)}
          >
            <img
              className={classes.buttonIcon}
              src={getButton(true)}
              alt={getButton(true)}
            />
          </a>

          <a
            className={`${classes.button} ${getButtonClass(false)}`}
            onClick={() => submit(false)}
          >
            <img
              className={classes.buttonIcon}
              src={getButton(false)}
              alt={getButton(false)}
            />
          </a>
        </div>
      </div>
      {isSubmit ? (
        <div className={classes.answer}>
          <div className={classes.headText}>解説</div>
          <div className={classes.summary}>
            <div className={classes.label} style={getAnswerLabelStyle()}>
              {result ? "正解！" : "不正解・・・"}
            </div>
            <div className={classes.text}>
              答えは{props.data.isCorrectChoice ? " ◯" : " ×"}
            </div>
          </div>
          <div className={classes.body}>{props.data.explanation}</div>
          <div className={classes.resultImageArea}>
            <img
              src={result ? Correct : Incorrect}
              alt={result ? Correct : Incorrect}
              className={classes.img}
            />
          </div>
        </div>
      ) : (
        <div className={classes.thinkingImageArea}>
          <img src={Thinking} alt="Thinking" className={classes.img} />
        </div>
      )}
    </div>
  );
};

export default Quiz;
