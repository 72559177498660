import classes from "./Privacy.module.scss";
import React, { CSSProperties, useEffect, useState } from "react";
import icon from "../../static/images/header_icon.svg";

const Privacy = () => {
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img className={classes.img} src={icon} alt={icon} />
      </div>
      <div className={classes.body}>
        <section className={classes.privacyPolicy}>
          <h1 className={classes.title}>
            「みらいいコンシェル」プライバシーポリシー
          </h1>
          <p className={classes.paragraph}>
            株式会社イノビオット（以下「当社」といいます。）は、当社が運営する「みらいいコンシェル」（以下「本サービス」といいます。）のサービス利用者（「みらいいコンシェル」利用規約に定義する意味を有します。）の個人情報の取扱いについて、次のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定め、本ポリシーに基づき適正に個人情報を取り扱います。
          </p>
          <p className={classes.paragraph}>
            なお、本ポリシーで使用する用語の意味は、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に準拠するものとします。
          </p>
          <h2 className={classes.heading}>1. (個人情報)</h2>
          当社は，お客様に関する次に掲げる個人情報を取得します。
          <p className={classes.listItem}>
            (1)「個人情報」とは、個人情報保護法にいう「個人情報」を指し、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるものまたは個人識別符号が含まれるものを指します。
          </p>
          <h2 className={classes.heading}>2. (個人情報の利用目的)</h2>
          当社は、以下の目的に必要な範囲で、サービス利用者の個人情報を取得し、取得した情報を利用させていただきます。以下の目的の範囲を超えて個人情報を利用する場合には、事前に適切な方法でサービス利用者からの同意を得るものとします。
          <p className={classes.listItem}>
            (1)当社のサービス（以下「本サービス」といいます。）を提供するため
          </p>
          <p className={classes.listItem}>
            (2)本サービス及び当社の他のサービスの内容を改良・改善し、または新サービスを開発するため
          </p>
          <p className={classes.listItem}>
            (3)本サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内（電子メール、チラシ、その他のダイレクトメールの送付を含みます。）のため
          </p>
          <p className={classes.listItem}>
            (4)メンテナンス、重要なお知らせ等必要に応じたご連絡のため
          </p>
          <p className={classes.listItem}>
            (5)本サービスに関するサービス利用者からのご意見、お問い合わせ等に回答するため（本人確認を行うことを含みます。）
          </p>
          <p className={classes.listItem}>
            (5)マーケティングデータの調査及び分析，並びに，マーケティング施策の検討及び実施のため
          </p>
          <p className={classes.listItem}>
            (6)本サービスの利用状況をサービス利用者にご報告するため
          </p>
          <p className={classes.listItem}>
            (7)本サービスに関するアンケート・取材等のご協力依頼や各種イベントへのご参加をお願いし、またはその結果等をご報告するため
          </p>
          <p className={classes.listItem}>
            (8)本サービスの利用履歴等を調査・分析し、その結果を本サービスの改良・開発や広告の配信に利用するため{" "}
          </p>
          <p className={classes.listItem}>
            (9)サービス利用者の承諾・申し込みに基づく、当社主催イベントの参加企業等への個人情報の提供
          </p>
          <p className={classes.listItem}>
            (10)利用規約に違反したサービス利用者や、不正・不当な目的で本サービスを利用しようとするサービス利用者の特定をし、ご利用をお断りするため
          </p>
          <h2 className={classes.heading}>3. (個人情報の管理と保護)</h2>
          <p className={classes.paragraph}>
            個人情報の管理は、厳重に行うこととし、次に掲げるときを除き、サービス利用者の同意がない限り、第三者に対し個人情報を開示・提供することはいたしません。また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。
          </p>
          <p className={classes.listItem}>
            (1)人の生命，身体又は財産の保護のために必要がある場合であって，お客様の同意を得ることが困難であるとき。
          </p>
          <p className={classes.listItem}>
            (2)公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって，お客様の同意を得ることが困難であるとき。
          </p>
          <p className={classes.listItem}>
            (3)国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。
          </p>
          <p className={classes.listItem}>(4)その他法令で認められる場合。</p>
          <h2 className={classes.heading}>4. (個人情報の取扱いの委託)</h2>
          <p className={classes.paragraph}>
            当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部または一部を委託する場合がございます。この場合、当社は、委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要かつ適切な監督を行います。
          </p>
          <h2 className={classes.heading}>5. (個人情報の開示)</h2>
          <p className={classes.paragraph}>
            サービス利用者は、当社に対し、第8条に定める手続に従って、当社の保有する個人情報の開示を請求することができます。当社は、サービス利用者から当該請求を受けたときは、サービス利用者に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
          </p>
          <p className={classes.listItem}>
            (1)利用目的の通知又は個人情報若しくは第三者提供記録の開示
          </p>
          <p className={classes.listItem}>
            お客様は，当社に対し，下記⑷に定める手続に従って，個人情報保護法において認められる範囲内において，利用目的の通知を求め，又は，個人情報若しくは第三者提供記録の開示を請求することができます。ただし，当社は，次の各号のいずれかに該当する場合は，開示を行わないことがあります。
          </p>
          <p className={classes.listItem}>
            (1)サービス利用者または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
          </p>
          <p className={classes.listItem}>
            (2)当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
          </p>
          <p className={classes.listItem}>(3)法令に違反することとなる場合</p>
          <h2 className={classes.heading}>6. (個人情報訂正等)</h2>
          <p className={classes.paragraph}>
            1.
            サービス利用者は、当社の保有する個人情報が誤った情報である場合には、当社に対し、第8条に定める手続に従って、当該個人情報の訂正、追加または削除（以下「訂正等」といいます。）を請求することができます。
          </p>
          <p className={classes.paragraph}>
            2.
            前項の請求を受けた場合、当社は遅滞なく必要な調査を行い、その結果前項の請求に理由があると判断した場合には、遅滞なく当該個人情報の訂正等を行います。
          </p>
          <p className={classes.paragraph}>
            3.
            当社は、前項に基づき訂正等の実施・不実施について判断した場合には、遅滞なく、サービス利用者ご本人に対してご連絡いたします。
          </p>
          <h2 className={classes.heading}>7. (個人情報の利用停止等)</h2>
          <p className={classes.paragraph}>
            1.
            サービス利用者は、当社に対し、第8条に定める手続に従って、当社の保有する個人情報の利用の停止、消去または第三者提供の停止（以下「利用停止等」といいます。）を請求することができます。
          </p>
          <p className={classes.paragraph}>
            2.
            当社は、前項の請求を受けた場合には、遅滞なく必要な調査を行い、その結果前項の請求に理由があると判断した場合には、当該個人情報の利用停止等を行います。ただし、個人情報の利用停止等に多額の費用を要する場合その他利用停止等を行うことが困難な場合であって、サービス利用者の権利利益を保護するために必要なこれに代わるべき措置をとれるときは、この代替策を講じます。
          </p>
          <p className={classes.paragraph}>
            3.
            当社は、前項に基づき利用停止等の実施・不実施について判断した場合には、遅滞なく、サービス利用者ご本人に対してご連絡いたします。
          </p>
          <h2 className={classes.heading}>8. (個人情報の開示等の手続)</h2>
          <p className={classes.paragraph}>
            サービス利用者は、第5条から第7条に定める請求を行う場合には、当社所定の請求書（下記ア）に所定事項を記入の上、本人確認書類（下記イ）を同封の上、当社の指定する送付先（下記ウ）までご郵送ください。また、個人情報の開示については、1回の請求につき1,000円の手数料をいただきます。請求書記載の振込先までお振込みください。なお、不開示の場合についても、所定の手数料をいただきます。
          </p>
          <p className={classes.listItem}>ア 以下のいずれかの請求書</p>
          <p className={classes.listItem}>・個人情報の開示の請求書</p>
          <p className={classes.listItem}>・訂正等の請求書</p>
          <p className={classes.listItem}>・利用停止等の請求の請求書</p>
          <p className={classes.listItem}>イ 本人確認書類</p>
          <p className={classes.listItem}>■お客様ご本人による請求の場合</p>
          <p className={classes.listItem}>
            ・お客様ご本人の本人確認書類（運転免許証、健康保険被保険者証、またはパスポートの写し（いずれか一つ））
          </p>
          <p className={classes.listItem}>■任意代理人による請求の場合</p>
          <p className={classes.listItem}>・委任状</p>
          <p className={classes.listItem}>
            ・任意代理人の本人確認書類（運転免許証、健康保険被保険者証、またはパスポートの写し（いずれか一つ））
          </p>
          <p className={classes.listItem}>■法定代理人による請求の場合</p>
          <p className={classes.listItem}>
            ・法定代理権の存在を確認できる書類（戸籍謄本または成年後見登記事項証明書）
          </p>
          <p className={classes.listItem}>
            ・法定代理人の本人確認書類（運転免許証、健康保険被保険者証、またはパスポートの写し（いずれか一つ））
          </p>
          <p className={classes.listItem}>ウ 送付先</p>
          <p className={classes.listItem}>
            〒260－0013 千葉県千葉市中央区中央二丁目5-1
          </p>
          <p className={classes.listItem}>
            株式会社イノビオット 個人情報開示請求窓口
          </p>
          <h2 className={classes.heading}>
            9. (プライバシーポリシーの変更手続)
          </h2>
          <p className={classes.paragraph}>
            当社は本ポリシーの内容を適宜見直し、その改善に努めます。本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、変更することができるものとします。変更後のプライバシーポリシーは、当社所定の方法により、サービス利用者に通知し、または当社ウェブサイトに掲載したときから効力を生じるものとします。
          </p>
          <h2 className={classes.heading}>10. (法令、規範の遵守)</h2>
          <p className={classes.paragraph}>
            当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守します。
          </p>
          <h2 className={classes.heading}>11. (苦情及び相談への対応)</h2>
          <p className={classes.paragraph}>
            当社は、個人情報の取扱いに関するサービス利用者からの苦情、相談を受け付け、適切かつ迅速に対応いたします。また、サービス利用者からの当該個人情報の開示、訂正、追加、削除、利用または提供の拒否等のご要望に対しても、迅速かつ適切に対応いたします。
          </p>
          <h2 className={classes.heading}>11. (安全管理措置)</h2>
          <p className={classes.paragraph}>
            当社がサービス利用者よりお預かりした個人情報は、個人情報ファイルへのアクセス制限の実施、アクセスログの記録及び外部からの不正アクセス防止のためのセキュリティ対策の実施等、組織的、物理的、人的、技術的施策を講じることで個人情報への不正な侵入、個人情報の紛失、破壊、改ざん、及び漏えい等を防止いたします。万一、サービス利用者の個人情報の漏えい等の事故が発生した場合、当社は、個人情報保護法及び関連するガイドラインに則り、速やかに監督官庁への報告を行うとともに、当該監督官庁の指示に従い、類似事案の発生防止措置及び再発防止措置等の必要な対応を行います。詳細については、「お問合せ窓口」まで問合せください。
          </p>
          <h2 className={classes.heading}>12.(お問い合わせ窓口)</h2>
          <p className={classes.listItem}>
            当社の個人情報の取扱いに関するお問い合せは以下までご連絡ください。
          </p>
          <p className={classes.listItem}>郵便番号：260-0013</p>
          <p className={classes.listItem}>
            住所：千葉県千葉市中央区中央二丁目5-1 千葉中央ツインビル2号館7階
          </p>
          <p className={classes.listItem}>e-mail：info@innoviot.co.jp</p>
          <p className={classes.listItem}>株式会社イノビオット</p>
          <p className={classes.listItem}>024年9月7日制定</p>
        </section>
      </div>
    </div>
  );
};
export default Privacy;
