import React, { useEffect, useState } from "react";
import charIcon from "./../../static/images/chara1.svg";
import charIcon2 from "./../../static/images/chara2.svg";
import classes from "./FirstSetting.module.scss";
import TextForm, { TextFormResult } from "../../atoms/text-form/TextForm";
import RadioButton from "../../atoms/radio-button/RadioButton";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import SelectBox, { SelectBoxOption } from "../../atoms/select-box/SelectBox";
import CheckBox, { CheckBoxItem } from "../../atoms/checkbox/CheckBox";
import { useNavigate } from "react-router-dom";
import {
  SchoolType,
  childItem,
  childSchools,
  gender,
  grade,
  isChildrenSection,
  isNotChildrenSection,
  relationships,
  schoolType,
  universityType,
  userTopic,
} from "./FirstSetting-const";
import {
  FamilyProfile,
  User,
  UserParam,
  UserTopic,
  fetchUser,
  postUsers,
} from "../../api/UsersApi";
import Header from "../../molecules/header/Header";
import { validators } from "../../common/utility/validator.util";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import RadioInCheckbox, {
  RadioInCheckBoxItem,
} from "../../organism/radio-in-checkbox/RadioInCheckBox";
import RadioInCheckBox from "../../organism/radio-in-checkbox/RadioInCheckBox";
import BadgeResult, {
  BadgeResultData,
} from "../../organism/badge-result/BadgeResult";
import BronzBadge from "../../static/images/BadgeBronzeWithStar.svg";
import removeIcon from "../../static/images/remove-icon.svg";

type Form = {
  myName: string | null;
  myRelationship: string | null;
  myBirthday: string | null;
  postalCode: string | null;
  existsPartner: string | null;
  partnerName: string | null;
  partnerRelationship: string | null;
  partnerBirthday: string | null;
  existsChildren: string | null;
  children: Child[];
};

type Child = {
  childName: string | null;
  childGeneder: string | null;
  childBirthday: string | null;
};

type Section = {
  no: string;
  title: string;
};

const FirstSetting = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [sections, setSections] = useState<Section[] | null>(null);
  const [formState, setFormState] = useState<number>(1);
  const fetchUserApi = fetchUser();
  const postUser = postUsers();
  const [answer, setAnswer] = useState<Form>({
    myName: null,
    myRelationship: null,
    myBirthday: null,
    postalCode: null,
    existsPartner: null,
    partnerName: null,
    partnerRelationship: null,
    partnerBirthday: null,
    existsChildren: null,
    children: [],
  });

  const [topic, setTopic] = useState<RadioInCheckBoxItem[]>(userTopic);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showResult, setShowResult] = useState<"hide" | "rankup">("rankup");
  const [data, setData] = useState<BadgeResultData | null>(null);

  const dateValidator = validators.date;
  const addressValidator = validators.adress;

  const changeAnswer = (e: TextFormResult | SelectBoxOption) => {
    setAnswer((prev) => ({ ...prev, [e.name]: e.value }));
  };

  const changeChild = (e: TextFormResult | SelectBoxOption, index: number) => {
    if (answer.children == null) {
      return;
    }
    const newChildren = [...answer.children];
    newChildren[index] = { ...newChildren[index], [e.name]: e.value };
    setAnswer((prev) => ({ ...prev, children: newChildren }));
  };

  const changeCheckBoxValue = (e: RadioInCheckBoxItem) => {
    const updatedTopic = topic?.map((t) => {
      if (t.id === userTopic.filter((i) => i.id === e.id)[0].id) {
        return e;
      }
      return t;
    });
    setTopic(updatedTopic);
  };

  const validateState1 = () => {
    return (
      answer.myName == null ||
      answer.myName == "" ||
      answer.myBirthday == null ||
      answer.myBirthday == "" ||
      answer.myRelationship == null ||
      answer.postalCode == null ||
      answer.postalCode == "" ||
      !dateValidator(answer.myBirthday).status ||
      !addressValidator(answer.postalCode).status
    );
  };

  const validateState2 = () => {
    if (answer.existsPartner === "0") {
      return false;
    }

    return (
      answer.existsPartner == null ||
      answer.partnerName == null ||
      answer.partnerName == "" ||
      answer.partnerRelationship == null ||
      answer.partnerBirthday == null ||
      answer.partnerBirthday == "" ||
      !dateValidator(answer.partnerBirthday).status
    );
  };

  const validateState3 = () => {
    if (answer.existsChildren === "0") {
      return false;
    }

    return answer.children.some((child) => {
      return (
        child.childGeneder == null ||
        child.childBirthday == null ||
        child.childBirthday == "" ||
        !dateValidator(child.childBirthday).status
      );
    });
  };

  const updateButtonDisabled = () => {
    if (formState === 1) {
      return validateState1();
    } else if (formState === 2) {
      return validateState2();
    } else if (formState === 3) {
      return validateState3();
    } else if (formState === 4) {
      return validateState1() || validateState2() || validateState3();
    }
    return false;
  };

  const submit = () => {
    const checkGneder = (relation: string) => {
      if (relation === "father") {
        return "male";
      } else if (relation === "mother") {
        return "female";
      } else {
        return null;
      }
    };
    const familyProfile: FamilyProfile[] = [
      {
        familyName: answer.myName,
        familyRelationship: "myself",
        childrenRelationship: answer.myRelationship,
        gender: checkGneder(answer.myRelationship ?? ""),
        birthdate: answer.myBirthday,
      },
    ];

    const existsPertner = answer.existsPartner === "1";

    // パートナーがいるならリクエストに追加
    if (existsPertner) {
      familyProfile.push({
        familyName: answer.partnerName,
        familyRelationship: "partner",
        childrenRelationship: answer.partnerRelationship,
        gender: checkGneder(answer.partnerRelationship ?? ""),
        birthdate: answer.partnerBirthday,
      });
    }

    // 子供がいるならリクエストに追加
    if (answer.children != null) {
      const children: FamilyProfile[] = answer?.children?.map((child) => {
        return {
          familyName: child.childName,
          familyRelationship: "children",
          childrenRelationship: null,
          gender: child.childGeneder,
          birthdate: child.childBirthday,
        };
      });
      familyProfile.push(...children);
    }

    const userTopic: UserTopic[] = topic.map((item) => {
      return {
        topicId: Number(item.id),
        interestLevel: item.value,
      };
    });

    if (answer.postalCode == null) {
      return;
    }

    const requestParam: UserParam = {
      isPartner: existsPertner,
      isChildren: answer.existsChildren === "1",
      postalCode: answer.postalCode,
      userTopic,
      familyProfile,
    };

    postUser.post(requestParam);
  };

  const changeState = () => {
    // 子供がいない場合は子供用のformを出さないので、stateが2の時は4にする
    if (!user?.isChildren && formState === 2) {
      setFormState(formState + 2);
    } else {
      setFormState(formState + 1);
    }
    setDisabled(updateButtonDisabled());
  };

  // 初回にユーザ情報を取得する
  useEffect(() => {
    fetchUserApi.fetch();

    setData({
      badgeExplanation: "",
      badgeImageData: "",
      badgeTitle: "",
      quizSummaryName: "",
      rankTitle: "ビギナー → ブロンズにランクアップ!",
      rankExplanation: [
        "ライフデータが開放されました",
        "ライフプラン-ゴール設定が開放されました",
        "ライフプランで確認できる項目が増えました",
      ],
      rankImageData: BronzBadge,
      id: "",
      actionType: "",
      equiredBadgeType: "bronze",
    });
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      return;
    }
    setUser(fetchUserApi.state?.data?.[0]);
  }, [fetchUserApi.state?.data]);

  useEffect(() => {
    if (user?.isChildren == null) {
      return;
    }

    if (!user.isFirstLogin) {
      navigate("/my-page");
    }

    // 子供の有無でフォームの数を変化させる
    if (user?.isChildren) {
      setSections(isChildrenSection);
    } else {
      setSections(isNotChildrenSection);
    }

    // 子供の数だけ子供用フォームの配列を作る
    setAnswer((prev) => ({
      ...prev,
      children: Array.from({ length: 1 }, () => createChildren()),
    }));
  }, [user]);

  // フォームの変化ごとにボタンの活性制御を行う
  useEffect(() => {
    setDisabled(updateButtonDisabled());
  }, [answer, formState]);

  useEffect(() => {
    if (postUser.state?.status === "success") {
      navigate("/my-page");
    }
  }, [postUser.state]);

  const addChild = () => {
    setAnswer((prev) => ({
      ...prev,
      children: [...prev.children, createChildren()],
    }));
  };

  const removeChild = (index: number) => {
    setAnswer((prev) => ({
      ...prev,
      children: prev.children.filter((_, i) => i !== index),
    }));
  };

  const createChildren = () => {
    return {
      childName: null,
      childGeneder: null,
      childBirthday: "2010-01-01",
    };
  };

  const closeResult = () => {
    setShowResult("hide");
  };

  return (
    <>
      {user?.isFirstLogin &&
        (showResult !== "hide" && data ? (
          <BadgeResult
            type={showResult}
            data={data}
            noCloseButton={true}
            close={closeResult}
          ></BadgeResult>
        ) : (
          <div className={classes.container}>
            <Header hideMenu={true} />
            <div className={classes.headerSub}>
              <TeacherComment
                image={charIcon}
                comment={[
                  "「将来に備えて今日からできるアクション」をご提案します！",
                  "あなたとあなたの家族について教えてください（3分程度）",
                ]}
              ></TeacherComment>
            </div>
            <div className={classes.selectArea}>
              <div className={classes.inputWrapper}>
                <div className={classes.heading}>
                  <div className={classes.headingNo}>
                    <span className={classes.headingNumerator}>
                      {sections?.[0].no}
                    </span>
                    <span className={classes.headingDenominator}>
                      /{sections?.length}
                    </span>
                  </div>
                  <div className={classes.headingTitle}>あなたのこと</div>
                </div>
                <div className={classes.input}>
                  <TextForm
                    name="myName"
                    value={answer.myName}
                    color="white"
                    label="ニックネーム"
                    subLabel={["15文字以内"]}
                    onChange={changeAnswer}
                    validator={(value: string) => {
                      return {
                        status: value.length <= 15,
                        message: "15文字以内にしてください",
                      };
                    }}
                  ></TextForm>
                </div>
                <div className={classes.input}>
                  <RadioButton
                    name="myRelationship"
                    value={answer.myRelationship}
                    items={relationships}
                    id="myRelationship"
                    label="あなたは子供にとって・・・"
                    onChange={changeAnswer}
                  ></RadioButton>
                </div>
                <div className={classes.input}>
                  <TextForm
                    name="myBirthday"
                    value={answer.myBirthday ?? "1990-01-01"}
                    placeholder="例）1990-01-01"
                    color="white"
                    label="誕生日"
                    type="text"
                    subLabel={["西暦＋月日を半角で入力してください。"]}
                    onChange={changeAnswer}
                    validator={dateValidator}
                  ></TextForm>
                </div>
                <div className={classes.input}>
                  <TextForm
                    name="postalCode"
                    value={answer.postalCode}
                    placeholder="例）105-0011"
                    color="white"
                    label="居住エリア"
                    type="text"
                    subLabel={["郵便番号を入力してください"]}
                    onChange={changeAnswer}
                    validator={addressValidator}
                  ></TextForm>
                </div>
              </div>
              {formState >= 2 && (
                <div className={classes.inputWrapper}>
                  <div className={classes.heading}>
                    <div className={classes.headingNo}>
                      <span className={classes.headingNumerator}>
                        {sections?.[1].no}
                      </span>
                      <span className={classes.headingDenominator}>
                        /{sections?.length}
                      </span>
                    </div>
                    <div className={classes.headingTitle}>
                      パートナー・配偶者のこと
                    </div>
                  </div>
                  <div className={classes.input}>
                    <RadioButton
                      name="existsPartner"
                      id="existsPartner"
                      value={answer.existsPartner}
                      // TODO: boolean用のラジオボタン作るべきか or 単一チェックボックスでやるか
                      items={[
                        {
                          display: "いる",
                          value: "1",
                        },
                        {
                          display: "いない",
                          value: "0",
                        },
                      ]}
                      label="現在、パートナー・配偶者は…"
                      onChange={changeAnswer}
                    ></RadioButton>
                  </div>
                  {answer.existsPartner === "1" && (
                    <>
                      <div className={classes.input}>
                        <TextForm
                          name="partnerName"
                          value={answer.partnerName}
                          color="white"
                          label="パートナー・配偶者のニックネーム"
                          subLabel={["15文字以内"]}
                          onChange={changeAnswer}
                          validator={(value: string) => {
                            return {
                              status: value.length <= 15,
                              message: "15文字以内にしてください",
                            };
                          }}
                        ></TextForm>
                      </div>
                      <div className={classes.input}>
                        <RadioButton
                          name="partnerRelationship"
                          id="partnerRelationship"
                          value={answer.partnerRelationship}
                          items={relationships}
                          label="パートナー・配偶者は子どもにとって…"
                          onChange={changeAnswer}
                        ></RadioButton>
                      </div>
                      <div className={classes.input}>
                        <TextForm
                          name="partnerBirthday"
                          value={answer.partnerBirthday ?? "1990-01-01"}
                          placeholder="例）1990-01-01"
                          type="text"
                          color="white"
                          label="パートナー・配偶者の誕生日"
                          subLabel={["西暦＋月日を半角で入力してください。"]}
                          onChange={changeAnswer}
                          validator={dateValidator}
                        ></TextForm>
                      </div>
                    </>
                  )}
                </div>
              )}
              {formState >= 3 && (
                <div className={classes.inputWrapper}>
                  <div className={classes.heading}>
                    <div className={classes.headingNo}>
                      <span className={classes.headingNumerator}>
                        {sections?.[2].no}
                      </span>
                      <span className={classes.headingDenominator}>
                        /{sections?.length}
                      </span>
                    </div>
                    <div className={classes.headingTitle}>子どものこと</div>
                  </div>
                  <div className={classes.input}>
                    <RadioButton
                      name="existsChildren"
                      id="existsChildren"
                      value={answer.existsChildren}
                      // TODO: boolean用のラジオボタン作るべきか or 単一チェックボックスでやるか
                      items={[
                        {
                          display: "いる",
                          value: "1",
                        },
                        {
                          display: "いない",
                          value: "0",
                        },
                      ]}
                      label="現在、子供は…"
                      onChange={changeAnswer}
                    ></RadioButton>
                  </div>
                  {answer.existsChildren === "1" &&
                    answer.children?.map((child, childIndex) => (
                      <>
                        <React.Fragment key={"children" + "-" + childIndex}>
                          {childIndex > 0 && (
                            <div
                              className={classes.removeChild}
                              onClick={() => removeChild(childIndex)}
                            >
                              <img src={removeIcon} />
                              <span className={classes.removeText}>
                                第{childIndex + 1}子の情報を削除
                              </span>
                            </div>
                          )}
                          <div className={classes.input}>
                            <TextForm
                              name="childName"
                              value={child.childName}
                              color="white"
                              label={`【第${childIndex + 1}子】のニックネーム`}
                              subLabel={["15文字以内"]}
                              onChange={(e) => changeChild(e, childIndex)}
                              validator={(value: string) => {
                                return {
                                  status: value.length <= 15,
                                  message: "15文字以内にしてください",
                                };
                              }}
                            ></TextForm>
                          </div>
                          <div className={classes.input}>
                            <RadioButton
                              name="childGeneder"
                              id={`childGeneder-${childIndex}`}
                              value={child.childGeneder}
                              items={gender}
                              label={`【第${childIndex + 1}子】の性別`}
                              onChange={(e) => changeChild(e, childIndex)}
                            ></RadioButton>
                          </div>
                          <div className={classes.input}>
                            <TextForm
                              name="childBirthday"
                              value={child.childBirthday ?? "2010-01-01"}
                              placeholder="例）2010-01-01"
                              type="text"
                              color="white"
                              label={`【第${childIndex + 1}子】の誕生日`}
                              subLabel={[
                                "西暦＋月日を半角で入力してください。",
                              ]}
                              onChange={(e) => changeChild(e, childIndex)}
                              validator={dateValidator}
                            ></TextForm>
                          </div>
                        </React.Fragment>
                      </>
                    ))}
                  {answer.existsChildren === "1" &&
                    answer.children?.length < 5 && (
                      <div className={classes.buttonArea}>
                        <SimpleButton
                          label={`+第${answer.children?.length + 1}子追加`}
                          width="80%"
                          isRadius={true}
                          color="black"
                          onClick={addChild}
                        ></SimpleButton>
                      </div>
                    )}
                </div>
              )}
              {formState >= 4 && (
                <div className={classes.inputWrapper}>
                  <div className={classes.heading}>
                    <div className={classes.headingNo}>
                      {user?.isChildren ? (
                        <>
                          <span className={classes.headingNumerator}>
                            {sections?.[3].no}
                          </span>
                          <span className={classes.headingDenominator}>
                            /{sections?.length}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className={classes.headingNumerator}>
                            {sections?.[2].no}
                          </span>
                          <span className={classes.headingDenominator}>
                            /{sections?.length}
                          </span>
                        </>
                      )}
                    </div>
                    <div className={classes.headingTitle}>興味・関心</div>
                  </div>
                  <div className={classes.input}>
                    <RadioInCheckBox
                      label="今、興味があるのは…"
                      subLabel="複数選択可"
                      checkItem={topic}
                      childItem={childItem}
                      onChange={changeCheckBoxValue}
                    ></RadioInCheckBox>
                  </div>
                  <div className={classes.headerSub}>
                    <TeacherComment
                      image={charIcon2}
                      comment={[
                        "ご協力ありがとうございました♪",
                        `${answer.myName}
                さんファミリーにぴったりなアクションをご提案いたします`,
                      ]}
                    ></TeacherComment>
                  </div>
                </div>
              )}
              {formState !== 4 ? (
                <div className={classes.buttonArea}>
                  <SimpleButton
                    label="次の質問へ"
                    width="60%"
                    isRadius={true}
                    disabled={disabled}
                    onClick={changeState}
                  ></SimpleButton>
                </div>
              ) : (
                <div className={classes.buttonArea}>
                  <SimpleButton
                    label="ライフプランをつくる"
                    width="80%"
                    maxWidth="343rem"
                    isRadius={true}
                    disabled={disabled}
                    onClick={submit}
                  ></SimpleButton>
                </div>
              )}
            </div>
          </div>
        ))}
    </>
  );
};

export default FirstSetting;
