import React, { useEffect, useState } from "react";
import Accordion from "../../molecules/accordion/Accordion";
import classes from "./FamilySetting.module.scss";
import Header from "../../molecules/header/Header";
import Footer from "../../molecules/footer/Footer";
import teacherImage from "../../static/images/teacher-ok.svg";
import arrowRight from "../../static/images/arrow-right.svg";
import plusIcon from "../../static/images/plus-circle.svg";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import Avatar from "../../atoms/avatar/Avatar";
import { UserFinanceItem, fetchUsersFinance } from "../../api/UsersFinanceApi";
import PageTitle from "../../atoms/page-title/PageTitle";
import { FamilyProfile, fetchUser } from "../../api/UsersApi";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import { fetchUsersGoal } from "../../api/UsersGoalApi";
import { useNavigate } from "react-router-dom";

type Money = {
  label: string;
  children: {
    label: string;
    value: string;
  }[];
};

const financeOrder = [
  "収入",
  "固定費",
  "変動費",
  "老後の収入",
  "将来の生活費",
  "将来の必要額",
  "資産形成",
];

const FamilySetting = () => {
  const [myself, setMyself] = useState<FamilyProfile>();
  const [partner, setPartner] = useState<FamilyProfile>();
  const [children, setChildren] = useState<FamilyProfile[]>();
  const fetchUserApi = fetchUser();
  const [money, setMoney] = useState<Money[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserApi.fetch();
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data != null) {
      const familyProfile = fetchUserApi.state?.data[0].familyProfile;

      if (familyProfile == null || familyProfile.length === 0) {
        return;
      }

      const myself = familyProfile.find(
        (v) => v.familyRelationship === "myself"
      );
      const partner = familyProfile.find(
        (v) => v.familyRelationship === "partner"
      );
      const children = familyProfile.filter(
        (v) => v.familyRelationship === "children"
      );
      setMyself(myself);
      setPartner(partner);
      setChildren(children);
    }
  }, [fetchUserApi.state?.status]);

  const clickDetail = (user: FamilyProfile) => {
    navigate(`/family-setting-detail?id=${user.familySequence}`);
  };

  return (
    <div className={classes.container}>
      <Header />
      <PageTitle title="家族の基本情報" />
      <div className={classes.body}>
        {myself != null && (
          <div className={classes.block} onClick={() => clickDetail(myself)}>
            <div className={classes.label}>あなた</div>
            <div className={classes.list}>
              <div className={classes.line}>
                <div className={classes.icon}>
                  <Avatar userName={myself.familyName ?? ""} />
                </div>
                <div className={classes.nameBlock}>
                  <div className={classes.name}>{myself.familyName}</div>
                </div>
                <img
                  className={classes.arrowIcon}
                  src={arrowRight}
                  alt={arrowRight}
                />
              </div>
            </div>
          </div>
        )}
        {partner != null && (
          <div className={classes.block} onClick={() => clickDetail(partner)}>
            <div className={classes.label}>パートナー・配偶者</div>
            <div className={classes.list}>
              <div className={classes.line}>
                <div className={classes.icon}>
                  <Avatar
                    backgroundColor={"#84C4FF"}
                    userName={partner.familyName ?? ""}
                  />
                </div>
                <div className={classes.nameBlock}>
                  <div className={classes.name}>{partner.familyName}</div>
                </div>
                <img
                  className={classes.arrowIcon}
                  src={arrowRight}
                  alt={arrowRight}
                />
              </div>
            </div>
          </div>
        )}
        {children != null && children.length > 0 && (
          <div className={classes.block}>
            <div className={classes.label}>子ども</div>
            <div className={classes.list}>
              {children.map((child, index) => {
                return (
                  <div
                    className={classes.line}
                    key={`${child.familyName}-${index}`}
                    onClick={() => clickDetail(child)}
                  >
                    <div className={classes.icon}>
                      <Avatar
                        backgroundColor={index === 0 ? "#EE8699" : "#D08FEF"}
                        userName={child.familyName ?? ""}
                      />
                    </div>
                    <div className={classes.nameBlock}>
                      <div className={classes.name}>{child.familyName}</div>
                      {/*  <div className={classes.option}>みらいいパーク未連携</div> */}
                    </div>
                    <img
                      className={classes.arrowIcon}
                      src={arrowRight}
                      alt={arrowRight}
                    />
                  </div>
                );
              })}
              {/* TODO 3/23時点ではOFF
              <div className={classes.line}>
                <img className={classes.icon} src={plusIcon} alt={plusIcon} />
                <div className={classes.nameBlock}>
                  <div className={classes.addChild}>子どもを追加</div>
                </div>
                <img
                  className={classes.arrowIcon}
                  src={arrowRight}
                  alt={arrowRight}
                />
              </div>
            */}
            </div>
          </div>
        )}

        {/* TODO 3/23時点ではOFF

        <TeacherComment
          image={teacherImage}
          comment={[
            "「おカネ」と「ゴール」の項目がすべてそろいましたね！時々見直してアップデートしましょう♪",
          ]}
        />
        */}
      </div>

      <div className={classes.breadCrumb}>
        <BreadCrumb
          data={[
            { displayName: "みらいいコンシェル", url: "/my-page" },
            { displayName: "家族の基本情報", url: "/family-setting" },
          ]}
        />
      </div>
    </div>
  );
};

export default FamilySetting;
