import React, { useEffect, useState } from "react";
import classes from "./EducationFeeCard.module.scss";
import { round } from "../../common/utility/number.util";
import { ChildEducationFee } from "../../api/UsersEducationApi";
import ArrowDown from "../../static/images/ArrowDown.svg";
import ArrowUp from "../../static/images/ArrowUp.svg";
import redoIcon from "../../static/images/redo.svg";
import { useNavigate } from "react-router-dom";

type Props = {
  customTitle?: string;
  color?: "blue";
  titleSize?: number;
  logoImage?: string;
  reCalc?: boolean;
  totalTuitionFee: number | undefined;
  totalTuitionFeeMonthlyBased: number | undefined;
  totalTuitionFeeYearlyBased: number | undefined;
  childEducation: ChildEducationFee[] | undefined;
};

const EducationFeeCard = (props: Props) => {
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();
  const getOuterCardStyle = () => {
    if (props.color === "blue") {
      return { backgroundColor: "#E8F0EE" };
    }

    return {
      backgroundColor: "#f9f7f3",
    };
  };

  const getTitleStyle = () => {
    if (props.titleSize != null) {
      return { fontSize: `${props.titleSize}rem` };
    }

    return {};
  };

  const openBreakdown = () => {
    setDisplay(!display);
  };

  const addStyle = display
    ? {
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
        borderTopLeftRadius: "16rem",
        borderTopRightRadius: "16rem",
      }
    : {};

  return (
    <div className={classes.container}>
      <div className={classes.outerCard} style={getOuterCardStyle()}>
        <div className={classes.title} style={getTitleStyle()}>
          {props.customTitle ?? "教育資金"}
          {props.reCalc && (
            <div
              className={classes.redo}
              onClick={() => navigate("/simulate-education")}
            >
              <img src={redoIcon} alt={redoIcon} />
              <span>再計算</span>
            </div>
          )}
        </div>
        {props.logoImage != null && (
          <div className={classes.logoContainer}>
            <img src={props.logoImage} alt={props.logoImage} />
          </div>
        )}
        <div className={classes.card}>
          <div className={classes.cardTitle}>
            これから必要になる教育費の総額
          </div>
          <div className={classes.cardBody}>
            <div className={classes.block}>
              <span className={classes.value}>
                {round((props.totalTuitionFee ?? 0) / 10000, "ceil", 0)}
              </span>
              <span className={classes.unit}>万円</span>
            </div>
            {props.totalTuitionFee !== 0 && (
              <div className={classes.breakdownArea}>
                <div
                  className={classes.breakdpwnTop}
                  style={addStyle}
                  onClick={openBreakdown}
                >
                  <img
                    src={display ? ArrowDown : ArrowUp}
                    alt={display ? ArrowDown : ArrowUp}
                  />
                  <div className={classes.breakdownTitle}>内訳</div>
                </div>
                {display && (
                  <div className={classes.breakdown}>
                    {props.childEducation?.map((child, index) => (
                      <div
                        key={`${child.childSequence}-${index}`}
                        className={classes.child}
                      >
                        <div className={classes.childNo}>第{index + 1}子</div>
                        <div className={classes.feeList}>
                          {child.primarySchoolName && (
                            <div className={classes.feeWrapper}>
                              <div className={classes.feeName}>
                                {child.primarySchoolName}
                              </div>
                              <div className={classes.fee}>
                                <div className={classes.value}>
                                  {round(
                                    child.primarySchoolFee / 10000,
                                    "ceil",
                                    0
                                  )}
                                </div>
                                <div className={classes.unit}>万円</div>
                              </div>
                            </div>
                          )}
                          {child.juniorSchoolName && (
                            <div className={classes.feeWrapper}>
                              <div className={classes.feeName}>
                                {child.juniorSchoolName}
                              </div>
                              <div className={classes.fee}>
                                <div className={classes.value}>
                                  {round(
                                    child.juniorSchoolFee / 10000,
                                    "ceil",
                                    0
                                  )}
                                </div>
                                <div className={classes.unit}>万円</div>
                              </div>
                            </div>
                          )}
                          {child.highSchoolName && (
                            <div className={classes.feeWrapper}>
                              <div className={classes.feeName}>
                                {child.highSchoolName}
                              </div>
                              <div className={classes.fee}>
                                <div className={classes.value}>
                                  {round(
                                    child.highSchoolFee / 10000,
                                    "ceil",
                                    0
                                  )}
                                </div>
                                <div className={classes.unit}>万円</div>
                              </div>
                            </div>
                          )}
                          {child.universityName && (
                            <div className={classes.feeWrapper}>
                              <div className={classes.feeName}>
                                {child.universityName}
                              </div>
                              <div className={classes.fee}>
                                <div className={classes.value}>
                                  {round(
                                    child.universityFee / 10000,
                                    "ceil",
                                    0
                                  )}
                                </div>
                                <div className={classes.unit}>万円</div>
                              </div>
                            </div>
                          )}
                          {child.studyAbroadName && (
                            <div className={classes.feeWrapper}>
                              <div className={classes.feeName}>
                                {child.studyAbroadName}
                              </div>
                              <div className={classes.fee}>
                                <div className={classes.value}>
                                  {round(
                                    child.studyAbroadFee / 10000,
                                    "ceil",
                                    0
                                  )}
                                </div>
                                <div className={classes.unit}>万円</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.cardTitle}>
            子ども全員が高校卒業するまでの積立額
          </div>
          <div
            className={classes.cardBody}
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <div className={classes.block}>
              <span className={classes.label}>月額</span>
              <span className={classes.value}>
                {round(
                  (props.totalTuitionFeeMonthlyBased ?? 0) / 10000,
                  "ceil",
                  0
                )}
              </span>
              <span className={classes.unit}>万円</span>
            </div>
            <div className={classes.block}>
              <span className={classes.label}>年間</span>
              <span className={classes.value}>
                {round(
                  (props.totalTuitionFeeYearlyBased ?? 0) / 10000,
                  "ceil",
                  0
                )}
              </span>
              <span className={classes.unit}>万円</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationFeeCard;
