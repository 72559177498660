import React, { useEffect, useState } from "react";
import classes from "./LifeDataInput.module.scss";
import Header from "../../molecules/header/Header";
import PageTitle from "../../atoms/page-title/PageTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherImage from "../../static/images/teacher-normal.svg";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import {
  fetchUsersFinance,
  postUsersFinance,
  PostUsersItem,
  UserFinanceItem,
  UserFinanceResponse,
} from "../../api/UsersFinanceApi";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import ArrowRight from "../../static/images/arrow-right.svg";
import {
  fetchUsersStaticFinance,
  StaticFinanceItem,
  UserStaticFinance,
} from "../../api/UsersStaticFinance";
import BadgeResult from "../../organism/badge-result/BadgeResult";

type DisplayData = {
  label: string;
  children: {
    label: string;
    value: string;
    item: UserFinanceItem | StaticFinanceItem;
  }[];
};

const subCategoryOrder = [
  "収入",
  "固定費",
  "変動費",
  "老後の収入",
  "将来の生活費",
  "将来の必要額",
  "資産形成",
];

const LifeDataInput = () => {
  const categoryMappper = [
    {
      id: "household_management",
      name: "家計管理",
    },
    {
      id: "second_life",
      name: "セカンドライフ",
    },
    {
      id: "asset_management",
      name: "資産形成",
    },
  ];
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("id");
  const categoryName = categoryMappper.find(
    (category) => category.id === categoryId
  )?.name;
  const [lifeData, setLifeData] = useState<DisplayData[] | null>(null);
  const [avarageData, setAvarageData] = useState<DisplayData[] | null>(null);
  const [displayData, setDisplayData] = useState<DisplayData[] | null>(null);
  const [isAvarage, setIsAvarage] = useState(false);
  const fetchUsersFinanceApi = fetchUsersFinance();
  const fetchUsersStaticFinanceApi = fetchUsersStaticFinance();
  const navigate = useNavigate();
  const postUsersFinanceApi = postUsersFinance();
  const [showResult, setShowResult] = useState<"hide" | "badge" | "rankup">(
    "hide"
  );
  const [lifeDataResponse, setLifeDataResponse] =
    useState<UserFinanceResponse | null>(null);

  const createValue = (
    item: UserFinanceItem | StaticFinanceItem,
    value: number | null
  ) => {
    if (value == null) {
      return "未入力";
    }

    if (item.periodicity == null) {
      return `${value}${item.unit ?? ""}`;
    }

    return `${value}${item.unit ?? ""}/${item.periodicity}`;
  };

  useEffect(() => {
    fetchUsersFinanceApi.fetch({
      majorCategoryId: "ALL",
    });

    if (categoryId == null) {
      return;
    }

    fetchUsersStaticFinanceApi.fetch({
      majorCategoryId: categoryId,
    });
  }, []);

  useEffect(() => {
    if (fetchUsersFinanceApi.state?.data == null) {
      return;
    }

    const data = fetchUsersFinanceApi.state.data[0].userFinanceItems.filter(
      (i) => i.majorCategoryId === categoryId
    );
    const categoryMap: { [key: string]: UserFinanceItem[] } = {};
    data.forEach((item) => {
      if (categoryMap[item.subCategoryName] == null) {
        categoryMap[item.subCategoryName] = [];
      }
      categoryMap[item.subCategoryName].push(item);
    });

    const lifeData = Object.keys(categoryMap)
      .sort((a, b) => {
        return subCategoryOrder.indexOf(a) - subCategoryOrder.indexOf(b);
      })
      .map((key) => {
        return {
          label: key,
          children: categoryMap[key].map((item) => {
            return {
              label: item.itemName,
              value: createValue(item, item.itemValue),
              item: item,
            };
          }),
        };
      });

    setLifeData(lifeData);
    setDisplayData(lifeData);
  }, [fetchUsersFinanceApi.state?.data]);

  useEffect(() => {
    if (fetchUsersStaticFinanceApi.state?.data == null) {
      return;
    }

    const data = fetchUsersStaticFinanceApi.state.data[0].staticFinanceItems;
    const categoryMap: { [key: string]: StaticFinanceItem[] } = {};
    data.forEach((item) => {
      if (categoryMap[item.subCategoryName] == null) {
        categoryMap[item.subCategoryName] = [];
      }
      categoryMap[item.subCategoryName].push(item);
    });

    const lifeData = Object.keys(categoryMap)
      .sort((a, b) => {
        return subCategoryOrder.indexOf(a) - subCategoryOrder.indexOf(b);
      })
      .map((key) => {
        return {
          label: key,
          children: categoryMap[key].map((item) => {
            return {
              label: item.itemName,
              value: createValue(item, item.itemAvgValue),
              item: item,
            };
          }),
        };
      });

    setAvarageData(lifeData);
  }, [fetchUsersStaticFinanceApi.state?.data]);

  const setAvarage = (isAvarage: boolean) => {
    setIsAvarage(isAvarage);
    setDisplayData(isAvarage ? avarageData : lifeData);
  };

  const update = (itemNumber: number) => {
    navigate(`/life-data-answer?id=${categoryId}`, {
      state: {
        data: { itemNumber },
      },
    });
  };

  const submit = () => {
    const answer: PostUsersItem[] = [];
    avarageData?.forEach((item) => {
      item.children.forEach((i) => {
        if (i.value != null && i.value !== "") {
          answer.push({
            itemId: i.item.itemId,
            itemValue: Number((i.item as StaticFinanceItem).itemAvgValue),
            majorCategoryId: i.item.majorCategoryId,
          });
        }
      });
    });

    postUsersFinanceApi.post({
      userFinanceItems: answer,
    });
  };

  const cancel = () => {
    setAvarage(false);
  };

  useEffect(() => {
    if (postUsersFinanceApi.state?.data == null) {
      return;
    }

    if (postUsersFinanceApi.state?.data[0]?.isRankup) {
      setShowResult("rankup");
      setLifeDataResponse(postUsersFinanceApi.state?.data[0]);
    } else {
      cleanup();
    }
  }, [postUsersFinanceApi.state?.data]);

  const cleanup = () => {
    postUsersFinanceApi.reset();
    setAvarage(false);
    setShowResult("hide");

    fetchUsersFinanceApi.fetch({
      majorCategoryId: "ALL",
    });
  };

  const close = () => {
    cleanup();
  };

  return (
    <>
      {showResult !== "hide" && lifeDataResponse != null ? (
        <BadgeResult
          type="rankup"
          data={{
            id: "",
            badgeExplanation: "",
            badgeImageData: "",
            rankTitle: lifeDataResponse.rankTitle,
            rankExplanation: lifeDataResponse.rankExplanation,
            rankImageData: lifeDataResponse.rankImageData,
            equiredBadgeType: lifeDataResponse.rankCategory,
          }}
          close={close}
        />
      ) : (
        <div className={classes.container}>
          <Header />
          <PageTitle title={categoryName ?? ""} />
          <div className={classes.teacherCommentWrapper}>
            <TeacherComment
              image={teacherImage}
              comment={
                isAvarage
                  ? [
                      "平均値を適用しました！",
                      "ご自身と差異のある項目を変更してくださいね♪",
                    ]
                  : [
                      "まずは平均値を適用して、ご自身と差異のある項目を変更してもOK♪",
                    ]
              }
            ></TeacherComment>
          </div>
          {!isAvarage && (
            <div className={classes.buttonArea}>
              <SimpleButton
                width="100%"
                label="サクッと平均値を一括適用する"
                onClick={() => setAvarage(true)}
              ></SimpleButton>
              <SimpleButton
                width="100%"
                label="1項目ずつコツコツ精査する"
                onClick={() => navigate(`/life-data-answer?id=${categoryId}`)}
              ></SimpleButton>
            </div>
          )}
          <div className={classes.listArea}>
            {displayData &&
              displayData.map((d, i) => (
                <div key={i} className={classes.parentContainer}>
                  <div className={classes.parentLabel}>
                    <div>{d.label}</div>
                  </div>
                  {d.children.map((c, j) => (
                    <div key={j} className={classes.childContainer}>
                      <div className={classes.label}>{c.label}</div>
                      <div
                        className={classes.valueWrapper}
                        onClick={() => update(c.item.itemNumber)}
                      >
                        <div className={classes.value}>{c.value}</div>
                        {!isAvarage && (
                          <img src={ArrowRight} alt="arrow-right" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            {isAvarage && (
              <>
                <div className={classes.buttonArea}>
                  <SimpleButton
                    label="保存する"
                    onClick={submit}
                    width="343rem"
                  />
                </div>
                <div className={classes.link} onClick={cancel}>
                  キャンセル
                </div>
              </>
            )}
          </div>
          <div className={classes.breadCrumb}>
            <BreadCrumb
              data={[
                { displayName: "みらいいコンシェル", url: "/my-page" },
                { displayName: "データ入力", url: "/life-data" },
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LifeDataInput;
