import React, { useEffect, useState } from "react";
import classes from "./LifeData.module.scss";
import PageTitle from "../../atoms/page-title/PageTitle";
import Header from "../../molecules/header/Header";
import Accordion from "../../molecules/accordion/Accordion";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { fetchUsersFinance, UserFinanceItem } from "../../api/UsersFinanceApi";
import ArrowDown from "../../static/images/ArrowDown.svg";
import ArrowUp from "../../static/images/ArrowUp.svg";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherImage from "../../static/images/teacher-normal.svg";
import ArrowRight from "../../static/images/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../../api/UsersApi";
import rockIcon from "../../static/images/rockIcon.png";

type LifeData = {
  label: string;
  children: {
    label: string;
    value: string;
  }[];
};

type DisplayData = {
  label: string;
  majorCategory: string;
  children: {
    label: string;
    children: {
      label: string;
      value: string;
    }[];
  }[];
};

const majorCategoryOrder = ["家計管理", "セカンドライフ", "資産形成"];
const subCategoryOrder = [
  "収入",
  "固定費",
  "変動費",
  "老後の収入",
  "将来の生活費",
  "将来の必要額",
  "資産形成",
];

const LifeData = () => {
  const [lifeData, setLifeData] = useState<DisplayData[] | null>(null);
  const { state, fetch } = fetchUsersFinance();
  const userApi = fetchUser();
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetch({
      majorCategoryId: "ALL",
    });
    userApi.fetch();
  }, []);

  useEffect(() => {
    if (state?.data == null) {
      return;
    }

    const data = state.data[0].userFinanceItems;
    const categoryMap: { [key: string]: UserFinanceItem[] } = {};
    data.forEach((d) => {
      if (!(d.majorCategoryName in categoryMap)) {
        categoryMap[d.majorCategoryName] = [];
      }
      categoryMap[d.majorCategoryName].push(d);
    });

    const createValue = (item: UserFinanceItem) => {
      if (item.itemValue == null) {
        return "未入力";
      }

      if (item.periodicity == null) {
        return `${item.itemValue}${item.unit ?? ""}`;
      }

      return `${item.itemValue}${item.unit ?? ""}/${item.periodicity}`;
    };
    const lifeData = Object.keys(categoryMap)
      .sort((a, b) => {
        return majorCategoryOrder.indexOf(a) - majorCategoryOrder.indexOf(b);
      })
      .map((key) => {
        const subCategoryMap: { [key: string]: UserFinanceItem[] } = {};
        categoryMap[key].forEach((a) => {
          if (key !== a.majorCategoryName) {
            return;
          }
          if (!(a.subCategoryName in subCategoryMap)) {
            subCategoryMap[a.subCategoryName] = [];
          }
          subCategoryMap[a.subCategoryName].push(a);
        });

        return {
          label: key,
          majorCategory: categoryMap[key][0].majorCategoryId,
          children: Object.keys(subCategoryMap)
            .sort((a, b) => {
              return subCategoryOrder.indexOf(a) - subCategoryOrder.indexOf(b);
            })
            .map((l) => {
              return {
                label: l,
                children: subCategoryMap[l]
                  .sort((a, b) => {
                    return a.itemNumber - b.itemNumber;
                  })
                  .map((item) => {
                    return {
                      label: item.itemName,
                      value: createValue(item),
                    };
                  }),
              };
            }),
        };
      });

    setLifeData(lifeData);
  }, [state?.data]);

  const openBreakdown = () => {
    setDisplay(!display);
  };

  const isUnderSilver = () => {
    return (
      userApi.state?.data?.[0]?.currentRank === "beginer" ||
      userApi.state?.data?.[0]?.currentRank === "bronze"
    );
  };

  const isUnderGold = () => {
    return (
      userApi.state?.data?.[0]?.currentRank === "beginer" ||
      userApi.state?.data?.[0]?.currentRank === "bronze" ||
      userApi.state?.data?.[0]?.currentRank === "silver"
    );
  };

  const handleColor = (label: string) => {
    if (label === "セカンドライフ") {
      if (isUnderSilver()) {
        return {
          backgroundColor: "#E0E0E0",
        };
      }
    } else if (label === "資産形成") {
      if (isUnderGold()) {
        return {
          backgroundColor: "#E0E0E0",
        };
      }
    }
    return {};
  };

  const handle = (d: DisplayData) => {
    if (d.label === "セカンドライフ" && isUnderSilver()) {
      return;
    }
    if (d.label === "資産形成" && isUnderGold()) {
      return;
    }
    navigate(`/life-data-input?id=${d.majorCategory}`);
  };

  const targetRank = (d: DisplayData) => {
    if (d.label === "セカンドライフ" && isUnderSilver()) {
      return false;
    }
    if (d.label === "資産形成" && isUnderGold()) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.container}>
      <Header />
      <PageTitle title="ライフデータ" />
      <div className={classes.body}>
        <div className={classes.block}>
          <div className={classes.listWithoutPad}>
            <div className={classes.breakdownArea}>
              <div className={classes.breakdpwnTop} onClick={openBreakdown}>
                <img
                  src={display ? ArrowDown : ArrowUp}
                  alt={display ? ArrowDown : ArrowUp}
                />
              </div>
              {lifeData &&
                lifeData.map((d, i) => (
                  <div key={i} className={classes.parentContainer}>
                    <div
                      className={classes.parentLabel}
                      style={handleColor(d.label)}
                      onClick={() => handle(d)}
                    >
                      <div>{d.label}</div>
                      <>
                        {isUnderSilver() && d.label === "セカンドライフ" && (
                          <div className={classes.tagContainer}>
                            <img src={rockIcon} alt="rockIcon" />
                            <div className={classes.tag}>シルバー限定</div>
                          </div>
                        )}
                        {isUnderGold() && d.label === "資産形成" && (
                          <div className={classes.tagContainer}>
                            <img src={rockIcon} alt="rockIcon" />
                            <div className={classes.tag}>ゴールド限定</div>
                          </div>
                        )}
                      </>
                      <img src={ArrowRight} alt={ArrowRight} />
                    </div>
                    {display &&
                      d.children.map(
                        (c, j) =>
                          targetRank(d) && (
                            <div key={i} className={classes.childrenContainer}>
                              <div className={classes.childrenLabel}>
                                {c.label}
                              </div>
                              {c.children.map((cc, k) => (
                                <div key={k} className={classes.childContainer}>
                                  <div className={classes.label}>
                                    {cc.label}
                                  </div>
                                  <div className={classes.value}>
                                    {cc.value}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                      )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={classes.teacherCommentWrapper}>
          <TeacherComment
            image={teacherImage}
            comment={[
              "入力項目がすべてそろいましたね！時々見直してアップデートしましょう♪",
            ]}
          ></TeacherComment>
        </div>
      </div>
      <div className={classes.breadCrumb}>
        <BreadCrumb
          data={[
            { displayName: "みらいいコンシェル", url: "/my-page" },
            { displayName: "データ入力", url: "/life-data" },
          ]}
        />
      </div>
    </div>
  );
};

export default LifeData;
