import React, { useEffect, useState } from "react";
import classes from "./EducationPlan.module.scss";
import { User } from "../../api/UsersApi";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherImage from "../../static/images/teacher-normal.svg";
import {
  getSchoolPlan,
  grade,
  schoolType,
  SchoolType,
  universityType,
  studyAbroadType
} from "../../pages/first-setting/FirstSetting-const";
import SelectBox, { SelectBoxOption } from "../../atoms/select-box/SelectBox";
import removeIcon from "../../static/images/remove-icon.svg";
import { ChildEducation } from "../../api/UsersEducationApi";

export type EducationPlan = {
  header: string;
  title: string[];
  logo: string;
  teacherComment: string[];
  children?: ChildEducation[];
  submitButtonLabel?: string;
};

type Props = {
  content: EducationPlan;
  prevCallback: () => void;
  submitCallback: (children: ChildEducation[]) => void;
};

const EducationPlan = (props: Props) => {
  const createInitParam = () => {
    return [
      {
        currentGrade: "preschool",
        kindergarden: "NA",
        primarySchool: "NA",
        juniorSchool: "NA",
        highSchool: "NA",
        university: "NA",
        studyAbroad:"NA",
        desiredOccupation: "",
      },
    ] as ChildEducation[];
  };
  const [childrenEducation, setChildrenEducation] =
    useState<ChildEducation[]>(createInitParam());

  useEffect(() => {
    if (props.content.children == null) {
      return;
    }

    setChildrenEducation(props.content.children);
  }, [props]);

  const addChild = () => {
    const init = createInitParam();
    setChildrenEducation([...childrenEducation, ...init]);
  };

  const removeChild = (index: number) => {
    setChildrenEducation(childrenEducation.filter((c, i) => i !== index));
  };
  const changeGrade = (e: SelectBoxOption, parentIndex: number) => {
    childrenEducation[parentIndex].currentGrade = e.value;
    setChildrenEducation(childrenEducation.map((c) => c));
  };

  const changeSchool = (
    e: SelectBoxOption,
    parentIndex: number,
    schoolType: SchoolType
  ) => {
    childrenEducation[parentIndex][schoolType.value] = e.value;
    setChildrenEducation(childrenEducation.map((c) => c));
  };

  const submit = () => {
    const childrenParam = childrenEducation.map((child) => {
      const schools = getSchools(child);
      const childParam = createInitParam()[0];
      childParam.currentGrade = child.currentGrade;
      schools.forEach((s) => {
        childParam[s.schoolType.value] = s.value;
      });

      return childParam;
    });
    props.submitCallback(childrenParam);
  };

  const getSchools = (child: ChildEducation) => {
    const schoolPlan = getSchoolPlan(child.currentGrade ?? "", -1);
    const schools = schoolPlan
      .map((s) => {
        const keyValue = Object.entries(child).find((v) => v[0] === s.value);
        if (keyValue == null) {
          return null;
        }

        const value =
          s.value === "studyAbroad"
          ? keyValue[1]  // studyAbroadの場合は、"NA" もそのまま保持
          : keyValue[1] == null || keyValue[1] == "NA"
            ? "public"
            : keyValue[1];
        return { schoolType: s, value };
      })
      .filter((v) => v != null) as {
      schoolType: SchoolType;
      value: string;
    }[];

    console.log(child);
    console.log(schoolPlan);
    console.log(schools);

    return schools;
  };

  return (
    <div className={classes.content}>
      <div className={classes.header}>{props.content.header}</div>
      <div className={classes.title}>
        {props.content.title.map((t) => {
          return <div key={t}>{t}</div>;
        })}
      </div>
      <img className={classes.logo} src={props.content.logo} />
      <div className={classes.teacherComment}>
        <TeacherComment
          image={teacherImage}
          comment={props.content.teacherComment}
        />
      </div>
      <div className={classes.formBlock}>
        {childrenEducation.map((child, parentIndex) => {
          return (
            <div className={classes.formContent} key={parentIndex}>
              <div className={classes.formHeader}>第{parentIndex + 1}子</div>
              {parentIndex > 0 && (
                <div
                  className={classes.removeChild}
                  onClick={() => removeChild(parentIndex)}
                >
                  <img src={removeIcon} />
                  <span className={classes.removeText}>
                    第{parentIndex + 1}子の情報を削除
                  </span>
                </div>
              )}
              <div className={classes.form}>
                <SelectBox
                  id={"current-grade" + "-" + parentIndex}
                  defaultValue={child.currentGrade ?? ""}
                  hideNone={true}
                  title={{ name: "現在の年齢・学年", value: "currentGrade" }}
                  option={grade}
                  onChange={(e) => changeGrade(e, parentIndex)}
                ></SelectBox>
              </div>
              {getSchools(child).map((school, childIndex) => (
                <div
                  className={classes.form}
                  key={school.value + childIndex.toString()}
                >
                  <SelectBox
                    id={"school" + "-" + parentIndex + "-" + childIndex}
                    key={"school" + "-" + parentIndex + "-" + childIndex}
                    defaultValue={school.value}
                    title={school.schoolType}
                    hideNone={true}
                    option={
                      school.schoolType.value === "university"
                        ? universityType
                        : school.schoolType.value === "studyAbroad"
                          ? studyAbroadType
                          : schoolType
                    }
                    onChange={(e) =>
                      changeSchool(e, parentIndex, school.schoolType)
                    }
                  ></SelectBox>
                </div>
              ))}
              <div className={classes.addButton} onClick={addChild}>
                ＋第{parentIndex + 2}子を追加
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes.submitButton} onClick={submit}>
        {props.content.submitButtonLabel ?? "診断結果を見る"}
      </div>
      <div className={classes.prev} onClick={props.prevCallback}>
        ひとつ前に戻る
      </div>
    </div>
  );
};

export default EducationPlan;
