import React, { useEffect, useRef, useState } from "react";
import classes from "./LifeDataAnswer.module.scss";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import BarHeader from "../../molecules/bar-header/BarHeader";
import Header from "../../molecules/header/Header";
import {
  fetchUsersStaticFinance,
  StaticFinanceItem,
} from "../../api/UsersStaticFinance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import TextForm, { TextFormResult } from "../../atoms/text-form/TextForm";
import teacherImage from "../../static/images/teacher-normal.svg";
import { SelectBoxOption } from "../../atoms/select-box/SelectBox";
import { validators } from "../../common/utility/validator.util";
import RadioButton from "../../atoms/radio-button/RadioButton";
import {
  postUsersFinance,
  PostUsersItem,
  UserFinanceResponse,
} from "../../api/UsersFinanceApi";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import QuizBadgeResult from "../../organism/quiz-badge-result/QuizBadgeResult";
import BadgeResult from "../../organism/badge-result/BadgeResult";

type DisplayData = {
  items: (StaticFinanceItem & {
    answerValue: string | null;
  })[];
};

const LifeDataAnswer = () => {
  const MAX_PROGRESS = 98;
  const progressContainerRef = useRef<HTMLDivElement | null>(null);
  const [progress, setProgress] = useState(1);
  const [startNumberPosition, setStartNumberPosition] = useState({});
  const [currentNo, setCurrentNo] = useState(1);
  const fetchUsersStaticFinanceApi = fetchUsersStaticFinance();
  const [params] = useSearchParams();
  const id = params.get("id");
  const [displayData, setDisplayData] = useState<DisplayData[]>([]);
  const [roundNum, setRoundNum] = useState(0);
  const [answerSum, setAnswerSum] = useState(0);
  const [disabled, setDisabled] = useState<boolean>(true);
  const numberValidator = validators.number;
  const requiredValidator = validators.required;
  const [housingState, setHousingState] = useState<string | null>("0");

  const navigate = useNavigate();
  const location = useLocation();
  // itemNumberが指定されている場合は、そのアイテムのみを表示する仕様
  const isItem = location.state?.data.itemNumber;
  const postUsersFinanceApi = postUsersFinance();
  const [showResult, setShowResult] = useState<"hide" | "badge" | "rankup">(
    "hide"
  );
  const [lifeDataResponse, setLifeDataResponse] =
    useState<UserFinanceResponse | null>(null);

  useEffect(() => {
    if (id == null) return;

    fetchUsersStaticFinanceApi.fetch({
      majorCategoryId: id,
    });
  }, []);

  useEffect(() => {
    if (fetchUsersStaticFinanceApi.state?.data == null) {
      return;
    }

    // itemNumberが指定されている場合は、そのアイテムのみを表示
    const data = (() => {
      if (isItem) {
        return fetchUsersStaticFinanceApi.state?.data[0].staticFinanceItems.filter(
          (i) => i.itemNumber === isItem
        );
      }
      return fetchUsersStaticFinanceApi.state?.data[0].staticFinanceItems;
    })();

    const sortedData = data.sort((a, b) => {
      return a.itemNumber - b.itemNumber;
    });

    const displayMap: { [key: string]: StaticFinanceItem[] } = {};
    sortedData.forEach((item) => {
      const sectionId = (() => {
        if (
          item.itemId === "rentBill" ||
          item.itemId === "mortgageAmount" ||
          item.itemId === "mortgageCompletionYear"
        ) {
          return "optional";
        }
      })();

      const key = sectionId === "optional" ? "optional" : item.itemId;

      if (!(key in displayMap)) {
        displayMap[key] = [];
      }

      displayMap[key].push(item);
    });

    const displayData = Object.keys(displayMap).map((key) => {
      return {
        items: displayMap[key].map((item) => {
          return {
            ...item,
            answerValue: item.itemValue,
          };
        }),
      };
    });

    setDisplayData(displayData);
    setAnswerSum(displayData.length);
  }, [fetchUsersStaticFinanceApi.state?.data]);

  const clickRoud = (num: number) => {
    setRoundNum(num);
    switch (num) {
      case 1:
        changeAnswer({
          name: String(displayData[currentNo - 1]?.items[0]?.itemId),
          value: String(displayData[currentNo - 1]?.items[0]?.itemMinValue),
        });
        break;
      case 2:
        changeAnswer({
          name: String(displayData[currentNo - 1]?.items[0]?.itemId),
          value: String(displayData[currentNo - 1]?.items[0]?.itemAvgValue),
        });
        break;
      case 3:
        changeAnswer({
          name: String(displayData[currentNo - 1]?.items[0]?.itemId),
          value: String(displayData[currentNo - 1]?.items[0]?.itemMaxValue),
        });
        break;
    }
  };

  const roundStyle = (num: number) => {
    return roundNum >= num ? { background: "#008E7E" } : {};
  };

  const innerRoundStyle = (num: number) => {
    return roundNum >= num ? { background: "#fff" } : {};
  };

  const changeAnswer = (e: TextFormResult | SelectBoxOption) => {
    setDisabled(
      ((e.value === "" || e.value === null) &&
        displayData[currentNo - 1]?.items.some((d) =>
          d.mandatory.includes("required")
        )) ||
        !validators.positive_number(e.value).status
    );

    const newDisplayData = displayData.map((item, index) => {
      if (index === currentNo - 1) {
        return {
          items: item.items.map((item) => {
            if (item.itemId === e.name) {
              return {
                ...item,
                answerValue: e.value,
              };
            }
            return item;
          }),
        };
      }

      return item;
    });
    setDisplayData(newDisplayData);
  };

  useEffect(() => {
    const progressContainer = progressContainerRef.current;
    if (progressContainer) {
      const progressContainerWidth = progressContainer.offsetWidth;
      const newLeftValue = (progress / 100) * progressContainerWidth - 12;
      setStartNumberPosition({
        left: `${newLeftValue}px`,
      });
    }
  }, [progress]);

  useEffect(() => {
    //プログレスバー更新
    currentNo === 1
      ? setProgress(0)
      : setProgress((currentNo / answerSum) * MAX_PROGRESS);
    setRoundNum(0);

    setDisabled(
      displayData[currentNo - 1]?.items.some((d) =>
        d.mandatory.includes("required")
      ) && displayData[currentNo - 1]?.items[0]?.answerValue === ""
    );
  }, [currentNo]);

  const selectHousingState = (e: SelectBoxOption) => {
    setHousingState(e.value);
  };

  const submit = () => {
    const answer: PostUsersItem[] = [];
    displayData.forEach((item) => {
      item.items.forEach((i) => {
        if (i.answerValue != null && i.answerValue !== "") {
          answer.push({
            itemId: i.itemId,
            itemValue: Number(i.answerValue),
            majorCategoryId: i.majorCategoryId,
          });
        }
      });
    });

    postUsersFinanceApi.post({
      userFinanceItems: answer,
    });
  };

  useEffect(() => {
    if (postUsersFinanceApi.state?.data == null) {
      return;
    }

    if (postUsersFinanceApi.state?.data[0]?.isRankup) {
      setShowResult("rankup");
      setLifeDataResponse(postUsersFinanceApi.state?.data[0]);
    } else {
      cleanup();
    }
  }, [postUsersFinanceApi.state?.data]);

  const close = () => {
    cleanup();
  };

  const cleanup = () => {
    setShowResult("hide");
    navigate(`/life-data-input?id=${id}`);
    postUsersFinanceApi.reset();
  };

  return (
    <>
      {showResult !== "hide" && lifeDataResponse != null ? (
        <BadgeResult
          type="rankup"
          data={{
            id: "",
            badgeExplanation: "",
            badgeImageData: "",
            rankTitle: lifeDataResponse.rankTitle,
            rankExplanation: lifeDataResponse.rankExplanation,
            rankImageData: lifeDataResponse.rankImageData,
            equiredBadgeType: lifeDataResponse.rankCategory,
          }}
          close={close}
        />
      ) : (
        <>
          <div className={classes.container}>
            <Header />
            <BarHeader title={displayData[0]?.items[0]?.majorCategoryName} />
            {/* 単一の更新のみだった場合はプログレスバーを非表示 */}
            {!isItem && (
              <>
                <div className={classes.progressBarContainer}>
                  <div
                    className={classes.progressContainer}
                    ref={progressContainerRef}
                  >
                    <div
                      className={classes.progressBar}
                      style={{ width: `${progress}%` }}
                    ></div>
                    <span
                      className={classes.startNumber}
                      style={startNumberPosition}
                    >
                      {currentNo}
                    </span>
                    <span className={classes.endNumber}>{answerSum}</span>
                  </div>
                </div>
                <div className={classes.header}>
                  <div className={classes.centerGroup}>
                    <div className={classes.index}>Q{currentNo}</div>
                    <div className={classes.slash}>/</div>
                    <div className={classes.total}>{answerSum}</div>
                  </div>
                </div>
              </>
            )}
            {displayData[currentNo - 1]?.items.length === 1 ? (
              <>
                <div className={classes.title}>
                  <div className={classes.subCategoryName}>
                    {displayData[currentNo - 1]?.items[0]?.subCategoryName}
                  </div>
                  <div className={classes.itemName}>
                    {displayData[currentNo - 1]?.items[0]?.itemName}
                  </div>
                </div>
                <div className={classes.teacherCommentWrapper}>
                  <TeacherComment
                    image={teacherImage}
                    comment={[
                      "当てはまる金額を選択肢から選択するか、直接入力しましょう♪",
                    ]}
                  ></TeacherComment>
                </div>
                <div className={classes.sliderArea}>
                  <div className={classes.sliderContainer}>
                    <div className={classes.border} style={roundStyle(1)}></div>
                    <div
                      className={classes.round}
                      style={roundStyle(1)}
                      onClick={() => clickRoud(1)}
                    >
                      <div className={classes.upperLabel}>安め</div>
                      <div
                        className={classes.innerRound}
                        style={innerRoundStyle(1)}
                      ></div>
                      <div className={classes.lowerLabel}>
                        {displayData[currentNo - 1]?.items[0]?.itemMinValue}
                        {displayData[currentNo - 1]?.items[0]?.unit ?? ""}
                      </div>
                    </div>
                    <div className={classes.border} style={roundStyle(2)}></div>
                    <div
                      className={classes.round}
                      style={roundStyle(2)}
                      onClick={() => clickRoud(2)}
                    >
                      <div className={classes.upperLabel}>平均値</div>
                      <div
                        className={classes.innerRound}
                        style={innerRoundStyle(2)}
                      ></div>
                      <div className={classes.lowerLabel}>
                        {displayData[currentNo - 1]?.items[0]?.itemAvgValue}
                        {displayData[currentNo - 1]?.items[0]?.unit ?? ""}
                      </div>
                    </div>
                    <div className={classes.border} style={roundStyle(3)}></div>
                    <div
                      className={classes.round}
                      style={roundStyle(3)}
                      onClick={() => clickRoud(3)}
                    >
                      <div className={classes.upperLabel}>高め</div>
                      <div
                        className={classes.innerRound}
                        style={innerRoundStyle(3)}
                      ></div>
                      <div className={classes.lowerLabel}>
                        {displayData[currentNo - 1]?.items[0]?.itemMaxValue}
                        {displayData[currentNo - 1]?.items[0]?.unit ?? ""}
                      </div>
                    </div>
                    <div className={classes.border} style={roundStyle(3)}></div>
                  </div>
                </div>
                <div className={classes.inputArea}>
                  {displayData[currentNo - 1]?.items[0]?.mandatory ===
                  "required" ? (
                    <span className={classes.required}>必須</span>
                  ) : (
                    <span className={classes.optional}>任意</span>
                  )}
                  <div className={classes.input}>
                    <TextForm
                      name={displayData[currentNo - 1]?.items[0]?.itemId}
                      value={displayData[currentNo - 1]?.items[0]?.answerValue}
                      color="white"
                      inputWidth="200rem"
                      subLabel={[
                        displayData[currentNo - 1]?.items[0]?.explanation,
                      ]}
                      onChange={changeAnswer}
                      validator={validators.positive_number}
                    ></TextForm>
                    <div className={classes.unit}>
                      {displayData[currentNo - 1]?.items[0]?.unit}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              // 住宅関連費だけは個別に実装
              <>
                <div className={classes.title}>
                  <div className={classes.subCategoryName}>
                    {displayData[currentNo - 1]?.items[0]?.subCategoryName}
                  </div>
                  <div className={classes.itemName}>住宅関連費</div>
                </div>
                <div className={classes.teacherCommentWrapper}>
                  <TeacherComment
                    image={teacherImage}
                    comment={["当てはまる値を入力しましょう♪"]}
                  ></TeacherComment>
                </div>
                <div className={classes.inputArea}>
                  <span className={classes.required}>必須</span>
                  <span className={classes.itemName}>持家・賃貸区分</span>
                  <RadioButton
                    name="housing"
                    id="housing"
                    value={housingState}
                    items={[
                      {
                        display: "持家",
                        value: "0",
                      },
                      {
                        display: "賃貸",
                        value: "1",
                      },
                    ]}
                    label=""
                    validator={requiredValidator}
                    onChange={selectHousingState}
                  ></RadioButton>
                </div>
                {housingState &&
                  (housingState === "0" ? (
                    <div className={classes.inputArea}>
                      {displayData[currentNo - 1]?.items[0]?.mandatory ===
                      "required" ? (
                        <span className={classes.required}>必須</span>
                      ) : (
                        <span className={classes.optional}>任意</span>
                      )}
                      <span className={classes.itemName}>
                        {displayData[currentNo - 1]?.items[0]?.itemName}
                      </span>
                      <div className={classes.input}>
                        <TextForm
                          name={displayData[currentNo - 1]?.items[0]?.itemId}
                          value={
                            displayData[currentNo - 1]?.items[0]?.answerValue
                          }
                          color="white"
                          inputWidth="200rem"
                          subLabel={[
                            displayData[currentNo - 1]?.items[0]?.explanation,
                          ]}
                          onChange={changeAnswer}
                          validator={validators.positive_number}
                        ></TextForm>
                        <div className={classes.unit}>
                          {displayData[currentNo - 1]?.items?.[0]?.unit}
                        </div>
                      </div>
                      {displayData[currentNo - 1]?.items[1]?.mandatory ===
                      "required" ? (
                        <span className={classes.required}>必須</span>
                      ) : (
                        <span className={classes.optional}>任意</span>
                      )}
                      <span className={classes.itemName}>
                        {displayData[currentNo - 1]?.items[1]?.itemName}
                      </span>
                      <div className={classes.input}>
                        <TextForm
                          name={displayData[currentNo - 1]?.items[1]?.itemId}
                          value={
                            displayData[currentNo - 1]?.items[1]?.answerValue
                          }
                          color="white"
                          inputWidth="200rem"
                          subLabel={[
                            displayData[currentNo - 1]?.items[1]?.explanation,
                          ]}
                          onChange={changeAnswer}
                          validator={validators.positive_number}
                        ></TextForm>
                        <div className={classes.unit}>
                          {displayData[currentNo - 1]?.items[1]?.unit}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.inputArea}>
                      {displayData[currentNo - 1]?.items[2]?.mandatory ===
                      "required" ? (
                        <span className={classes.required}>必須</span>
                      ) : (
                        <span className={classes.optional}>任意</span>
                      )}
                      <span className={classes.itemName}>
                        {displayData[currentNo - 1]?.items[2]?.itemName}
                      </span>
                      <div className={classes.input}>
                        <TextForm
                          name={displayData[currentNo - 1]?.items[2]?.itemId}
                          value={
                            displayData[currentNo - 1]?.items[2]?.answerValue
                          }
                          color="white"
                          inputWidth="200rem"
                          subLabel={[
                            displayData[currentNo - 1]?.items[2]?.explanation,
                          ]}
                          onChange={changeAnswer}
                          validator={validators.positive_number}
                        ></TextForm>
                        <div className={classes.unit}>
                          {displayData[currentNo - 1]?.items[2]?.unit}
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
            <div className={classes.buttonArea}>
              {isItem ? (
                <SimpleButton
                  label="完了"
                  disabled={disabled}
                  onClick={submit}
                  width="343rem"
                />
              ) : (
                <>
                  <SimpleButton
                    label="前へ"
                    onClick={() => setCurrentNo(currentNo - 1)}
                    disabled={currentNo < 2}
                    width="343rem"
                  />
                  {currentNo !== answerSum ? (
                    <SimpleButton
                      label="次へ"
                      disabled={disabled}
                      onClick={() => setCurrentNo(currentNo + 1)}
                      width="343rem"
                    />
                  ) : (
                    <SimpleButton
                      label="完了"
                      disabled={disabled}
                      onClick={submit}
                      width="343rem"
                    />
                  )}
                </>
              )}
            </div>
            <div
              className={classes.link}
              onClick={() => navigate(`/life-data-input?id=${id}`)}
            >
              キャンセル
            </div>
          </div>
          <div className={classes.breadCrumb}>
            <BreadCrumb
              data={[
                { displayName: "みらいいコンシェル", url: "/my-page" },
                { displayName: "データ入力", url: "/life-data" },
                {
                  displayName: "あなたの手取り変更",
                  url: `/life-data-answer?id=${id}`,
                },
              ]}
            />
          </div>
        </>
      )}
    </>
  );
};

export default LifeDataAnswer;
